import type { TypePolicy } from '@apollo/client';
import type {
  BasicFormOption,
  ConfigProgress,
  EpTimelineItem,
  SelectedOptionGrant,
  SelectedShareGrant,
  Subdivision,
} from '@generated';
import { RsuGrantType } from '@generated';
import { getFullName, getInitials } from 'src/utils/strings';

import {
  TypePolicies,
  baseTypePolicies,
  baseUuidTypePolicy,
} from 'src/@generated/typePolicies';

import { customersMapReactiveVar } from './reactiveVars';

const disabledTypePolicy: TypePolicy = {
  keyFields: false,
};

function mergeUsingIncoming<T>() {
  return (_cached: T, incoming: T) => incoming;
}

const GRANTS_BY_CUSTOMER_KEY = 'BY_CUSTOMER';

const disabledTypePolicies: Partial<TypePolicies> = {
  CurrencyModel: disabledTypePolicy,
  Feedback: disabledTypePolicy,
  SelectedOptionGrant: disabledTypePolicy,
  SelectedShareGrant: disabledTypePolicy,
};

const customTypePolicies: Partial<TypePolicies> = {
  FinancingFormStatus: {
    keyFields: ['name'],
  },
  EquityQueries: {
    fields: {
      /**
       * We need this custom field policy because the `getAllGrants` query can be called with or without an `affiliation` arg and apollo isn't able to normalize the cache correctly.
       * @property {Function} read - Reads the cache data. If an `affiliation` arg is present, it retrieves the data stored under that affiliation. Otherwise, it retrieves the data from the `BY_CUSTOMER` key.
       *
       * @property {Function} merge - Combines the existing cached data with incoming data.
       * Determines the key (either specific `affiliation` or general `BY_CUSTOMER`) to store the incoming data under.
       * If there's existing data under that key, it gets overridden by the new data.
       *
       * @example
       * When querying for `getAllGrants` with an `affiliation` of 'abc-123', and then later querying
       * with 'def-456', the cache should have separate entries for both 'abc-123' and 'def-456'.
       * If you then query without an `affiliation`, the results should be stored under the `BY_CUSTOMER` key.
       *
       * The resulting cache structure might look something like:
       * {
       *   'abc-123': {...data},
       *   'def-456': {...data},
       *   'BY_CUSTOMER': {...data}
       * }
       */
      getAllGrants: {
        read(existing, { args }) {
          if (args?.affiliation) {
            return existing?.[args.affiliation];
          } else {
            return existing?.[GRANTS_BY_CUSTOMER_KEY];
          }
        },
        merge(existing, incoming, { args }) {
          const affiliationKey = args?.affiliation ?? GRANTS_BY_CUSTOMER_KEY;
          return {
            ...existing,
            [affiliationKey]: incoming,
          };
        },
      },
    },
  },
  Query: {
    fields: {
      equity: {
        merge(existing, incoming, { mergeObjects }) {
          return mergeObjects(existing, incoming);
        },
      },
      allAffiliations: {
        merge: mergeUsingIncoming<{
          __typename: 'AllAffiliationsResponse';
          nodes: { __ref: string }[];
        }>(),
      },
      totalNetWorth: {
        // combine all the values from the cache and the incoming value, as no fields overlap
        merge(cached, incoming) {
          return { ...cached, ...incoming };
        },
      },
      assetTypeOverview: {
        // combine all the values from the cache and the incoming value, as no fields overlap
        merge(cached, incoming) {
          return { ...cached, ...incoming };
        },
      },
    },
  },
  Proposal: {
    ...baseUuidTypePolicy,
    fields: {
      option_grants: {
        merge: mergeUsingIncoming<SelectedOptionGrant[]>(),
      },
      share_grants: {
        merge: mergeUsingIncoming<SelectedShareGrant[]>(),
      },
    },
  },
  ProposalRequest: {
    ...baseUuidTypePolicy,
    fields: {
      proposal_reason: {
        merge(_: string[], incoming: string[]) {
          return incoming;
        },
      },
    },
  },
  RSUGrant: {
    ...baseUuidTypePolicy,
    fields: {
      grantType: {
        read: () => {
          return RsuGrantType.Rsu;
        },
      },
    },
  },
  User: {
    ...baseUuidTypePolicy,
    fields: {
      fullName: {
        read: (_, { readField }) => {
          return getFullName({
            first_name: readField('first_name'),
            last_name: readField('last_name'),
          });
        },
      },
      initials: {
        read: (_, { readField }) => {
          return getInitials({
            first_name: readField('first_name'),
            last_name: readField('last_name'),
          });
        },
      },
      customer: {
        read: (_, { variables }) => {
          const userUuid = variables?.uuid;

          if (!userUuid) {
            return null;
          }

          const customers = customersMapReactiveVar();
          return customers[userUuid] || null;
        },
      },
    },
  },
  FormOptions: {
    fields: {
      subdivisions: {
        merge: mergeUsingIncoming<Subdivision[]>(),
      },
      countries: {
        merge: mergeUsingIncoming<BasicFormOption[]>(),
      },
    },
  },
  EPTimeline: {
    keyFields: ['equityPlanConfigUuid'],
    fields: {
      configProgress: {
        merge: mergeUsingIncoming<ConfigProgress>(),
      },
      items: {
        merge: mergeUsingIncoming<EpTimelineItem[]>(),
      },
    },
  },
  CustomerFlag: {
    keyFields: ['customer', 'affiliation', 'flag'],
  },
};

export const typePolicies: Partial<TypePolicies> = {
  ...baseTypePolicies,
  ...disabledTypePolicies,
  ...customTypePolicies,
};
