/*
  This file is intended as a poor-man's singleton for the shared services.

  Generally I'm against this kind of approach - the shared services must
  be injected via dependency injection mechanism, like React Context.

  However, in anticipation of our move to vite and Remix SPA this seems like
  the best choice. In Remix SPA we'll need to access the clients and services
  from "loaders" defined as functions in the module scope. Remix SPA currently
  lacks any mechanism for dependency injection in "loaders", so we'll have to
  resort to this approach for now.

  Where possible, the services will still be injected via DI.
*/
import { createApolloSdk } from './services/apiClients/createApolloSdk';
import { createGraphQlClient } from './services/apiClients/createGraphQlClient';
import { createRestApiClient } from './services/apiClients/createRestApiClient';
import { createAuthenticationService } from './services/authentication/createAuthenticationService';

export const authenticationService = createAuthenticationService();

export const restApiClient = createRestApiClient(
  authenticationService.getToken,
  authenticationService.ensureTokensLiveness
);

export const apolloClient = createGraphQlClient(
  authenticationService.getToken,
  authenticationService.ensureTokensLiveness
);

/**
 * The purpose of Apollo SDK is to provide a convenient way to invoke
 * GraphQL queries directly against the client (most useful in Remix's
 * client loaders). Instead of:
 *
 * ```
 * import { apolloClient } from 'src/shared';
 *
 * import {
 *   GetPortfolioOverviewAllocationTableDocument,
 *   GetPortfolioOverviewAllocationTableQuery,
 *   GetPortfolioOverviewAllocationTableQueryVariables,
 *   PortfolioManagedBySelector,
 * } from '@generated';
 *
 * const result = await apolloClient.query<
 *   GetPortfolioOverviewAllocationTableQuery,
 *   GetPortfolioOverviewAllocationTableQueryVariables
 * >({
 *   query: GetPortfolioOverviewAllocationTableDocument,
 *   variables: {
 *     customer: customerId,
 *     managedBy: PortfolioManagedBySelector.All,
 *   },
 * }),
 * ```
 *
 * It is possible to do this:
 *
 * ```
 * import { apolloSdk } from 'src/shared';
 *
 * import {
 *   PortfolioManagedBySelector,
 * } from '@generated';
 *
 * const result = await apolloSdk.getPortfolioOverviewAllocationTable({
 *   customer: customerId,
 *   managedBy: PortfolioManagedBySelector.All,
 * });
 * ```
 *
 * All while being type-safe!
 */
export const apolloSdk = createApolloSdk(apolloClient);

authenticationService.addLogoutHandler(() => {
  apolloClient.clearStore();

  try {
    apolloClient.getObservableQueries('all').forEach((query) => {
      // @ts-expect-error using some private APIs here
      query.queryManager.stopQueryNoBroadcast(query.queryId);
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
});
