/* tslint:disable */

/* istanbul ignore file */
import type {
  ApolloClient,
  ApolloQueryResult,
  FetchResult,
  NormalizedCacheObject,
} from '@apollo/client';

import * as Operations from 'src/@generated/facadeClient';
import type * as Types from 'src/@generated/types';

type SdkQueryFunctionType<Q, V> = V extends { [key: string]: never }
  ? (variables?: V) => Promise<ApolloQueryResult<Q>>
  : (variables: V) => Promise<ApolloQueryResult<Q>>;

type SdkMutationFunctionType<M, V> = V extends { [key: string]: never }
  ? (variables?: V) => Promise<FetchResult<M>>
  : (variables: V) => Promise<FetchResult<M>>;

export interface SecfiApolloSdk {
  latestPublicPriceForDate: SdkQueryFunctionType<
    Types.LatestPublicPriceForDateQuery,
    Types.LatestPublicPriceForDateQueryVariables
  >;
  processCartaSignupCallback: SdkMutationFunctionType<
    Types.ProcessCartaSignupCallbackMutation,
    Types.ProcessCartaSignupCallbackMutationVariables
  >;
  createAccountPasswordless: SdkMutationFunctionType<
    Types.CreateAccountPasswordlessMutation,
    Types.CreateAccountPasswordlessMutationVariables
  >;
  userIdentification: SdkQueryFunctionType<
    Types.UserIdentificationQuery,
    Types.UserIdentificationQueryVariables
  >;
  getMockProposal: SdkQueryFunctionType<
    Types.GetMockProposalQuery,
    Types.GetMockProposalQueryVariables
  >;
  getBankAccountInformation: SdkQueryFunctionType<
    Types.GetBankAccountInformationQuery,
    Types.GetBankAccountInformationQueryVariables
  >;
  createOrUpdateBankAccountInformation: SdkMutationFunctionType<
    Types.CreateOrUpdateBankAccountInformationMutation,
    Types.CreateOrUpdateBankAccountInformationMutationVariables
  >;
  getCustomerBankAccountInformation: SdkQueryFunctionType<
    Types.GetCustomerBankAccountInformationQuery,
    Types.GetCustomerBankAccountInformationQueryVariables
  >;
  createOrUpdateCustomerBankAccountInformation: SdkMutationFunctionType<
    Types.CreateOrUpdateCustomerBankAccountInformationMutation,
    Types.CreateOrUpdateCustomerBankAccountInformationMutationVariables
  >;
  getComplianceInformation: SdkQueryFunctionType<
    Types.GetComplianceInformationQuery,
    Types.GetComplianceInformationQueryVariables
  >;
  createOrUpdateComplianceInformation: SdkMutationFunctionType<
    Types.CreateOrUpdateComplianceInformationMutation,
    Types.CreateOrUpdateComplianceInformationMutationVariables
  >;
  getEquityVerification: SdkQueryFunctionType<
    Types.GetEquityVerificationQuery,
    Types.GetEquityVerificationQueryVariables
  >;
  createOrUpdateEquityVerification: SdkMutationFunctionType<
    Types.CreateOrUpdateEquityVerificationMutation,
    Types.CreateOrUpdateEquityVerificationMutationVariables
  >;
  getFinancingOptionGrants: SdkQueryFunctionType<
    Types.GetFinancingOptionGrantsQuery,
    Types.GetFinancingOptionGrantsQueryVariables
  >;
  updateFinancingOptionGrant: SdkMutationFunctionType<
    Types.UpdateFinancingOptionGrantMutation,
    Types.UpdateFinancingOptionGrantMutationVariables
  >;
  getFinancingShareGrants: SdkQueryFunctionType<
    Types.GetFinancingShareGrantsQuery,
    Types.GetFinancingShareGrantsQueryVariables
  >;
  updateFinancingShareGrant: SdkMutationFunctionType<
    Types.UpdateFinancingShareGrantMutation,
    Types.UpdateFinancingShareGrantMutationVariables
  >;
  getIdentificationInformation: SdkQueryFunctionType<
    Types.GetIdentificationInformationQuery,
    Types.GetIdentificationInformationQueryVariables
  >;
  createOrUpdateIdentificationInformation: SdkMutationFunctionType<
    Types.CreateOrUpdateIdentificationInformationMutation,
    Types.CreateOrUpdateIdentificationInformationMutationVariables
  >;
  startKycVerification: SdkMutationFunctionType<
    Types.StartKycVerificationMutation,
    Types.StartKycVerificationMutationVariables
  >;
  endKycVerification: SdkMutationFunctionType<
    Types.EndKycVerificationMutation,
    Types.EndKycVerificationMutationVariables
  >;
  getIntermediaryServicesAgreement: SdkQueryFunctionType<
    Types.GetIntermediaryServicesAgreementQuery,
    Types.GetIntermediaryServicesAgreementQueryVariables
  >;
  getPersonalInformation: SdkQueryFunctionType<
    Types.GetPersonalInformationQuery,
    Types.GetPersonalInformationQueryVariables
  >;
  getPersonalInformationCitizenshipOptions: SdkQueryFunctionType<
    Types.GetPersonalInformationCitizenshipOptionsQuery,
    Types.GetPersonalInformationCitizenshipOptionsQueryVariables
  >;
  createOrUpdatePersonalInformation: SdkMutationFunctionType<
    Types.CreateOrUpdatePersonalInformationMutation,
    Types.CreateOrUpdatePersonalInformationMutationVariables
  >;
  getTaxWithholdings: SdkQueryFunctionType<
    Types.GetTaxWithholdingsQuery,
    Types.GetTaxWithholdingsQueryVariables
  >;
  updateTaxWithholdings: SdkMutationFunctionType<
    Types.UpdateTaxWithholdingsMutation,
    Types.UpdateTaxWithholdingsMutationVariables
  >;
  updateTaxWithholdingsOptionRelation: SdkMutationFunctionType<
    Types.UpdateTaxWithholdingsOptionRelationMutation,
    Types.UpdateTaxWithholdingsOptionRelationMutationVariables
  >;
  getW9Form: SdkQueryFunctionType<
    Types.GetW9FormQuery,
    Types.GetW9FormQueryVariables
  >;
  requestProposalRegeneration: SdkMutationFunctionType<
    Types.RequestProposalRegenerationMutation,
    Types.RequestProposalRegenerationMutationVariables
  >;
  confirmAcceptProposal: SdkMutationFunctionType<
    Types.ConfirmAcceptProposalMutation,
    Types.ConfirmAcceptProposalMutationVariables
  >;
  createProposalRequest: SdkMutationFunctionType<
    Types.CreateProposalRequestMutation,
    Types.CreateProposalRequestMutationVariables
  >;
  initContractSigning: SdkMutationFunctionType<
    Types.InitContractSigningMutation,
    Types.InitContractSigningMutationVariables
  >;
  processScheduledCall: SdkMutationFunctionType<
    Types.ProcessScheduledCallMutation,
    Types.ProcessScheduledCallMutationVariables
  >;
  submitFinancingOnboardingForm: SdkMutationFunctionType<
    Types.SubmitFinancingOnboardingFormMutation,
    Types.SubmitFinancingOnboardingFormMutationVariables
  >;
  updateProposalRequest: SdkMutationFunctionType<
    Types.UpdateProposalRequestMutation,
    Types.UpdateProposalRequestMutationVariables
  >;
  getSeriesContract: SdkQueryFunctionType<
    Types.GetSeriesContractQuery,
    Types.GetSeriesContractQueryVariables
  >;
  getInvestmentDirectionLetter: SdkQueryFunctionType<
    Types.GetInvestmentDirectionLetterQuery,
    Types.GetInvestmentDirectionLetterQueryVariables
  >;
  getSpringingLienAssignmentAgreement: SdkQueryFunctionType<
    Types.GetSpringingLienAssignmentAgreementQuery,
    Types.GetSpringingLienAssignmentAgreementQueryVariables
  >;
  getSpringingLienPsc: SdkQueryFunctionType<
    Types.GetSpringingLienPscQuery,
    Types.GetSpringingLienPscQueryVariables
  >;
  getSpringingLienStockPledge: SdkQueryFunctionType<
    Types.GetSpringingLienStockPledgeQuery,
    Types.GetSpringingLienStockPledgeQueryVariables
  >;
  getPrivateSecuritiesContract: SdkQueryFunctionType<
    Types.GetPrivateSecuritiesContractQuery,
    Types.GetPrivateSecuritiesContractQueryVariables
  >;
  getFinancingCategoryStatuses: SdkQueryFunctionType<
    Types.GetFinancingCategoryStatusesQuery,
    Types.GetFinancingCategoryStatusesQueryVariables
  >;
  getProposal: SdkQueryFunctionType<
    Types.GetProposalQuery,
    Types.GetProposalQueryVariables
  >;
  getProposalExitComparison: SdkQueryFunctionType<
    Types.GetProposalExitComparisonQuery,
    Types.GetProposalExitComparisonQueryVariables
  >;
  getProposalRequest: SdkQueryFunctionType<
    Types.GetProposalRequestQuery,
    Types.GetProposalRequestQueryVariables
  >;
  getProposalTaxDetails: SdkQueryFunctionType<
    Types.GetProposalTaxDetailsQuery,
    Types.GetProposalTaxDetailsQueryVariables
  >;
  addSecondariesRequest: SdkMutationFunctionType<
    Types.AddSecondariesRequestMutation,
    Types.AddSecondariesRequestMutationVariables
  >;
  updateFinancingObject: SdkMutationFunctionType<
    Types.UpdateFinancingObjectMutation,
    Types.UpdateFinancingObjectMutationVariables
  >;
  updateSecondariesRequest: SdkMutationFunctionType<
    Types.UpdateSecondariesRequestMutation,
    Types.UpdateSecondariesRequestMutationVariables
  >;
  getFinancingBasicInfo: SdkQueryFunctionType<
    Types.GetFinancingBasicInfoQuery,
    Types.GetFinancingBasicInfoQueryVariables
  >;
  getFinancingFormStatuses: SdkQueryFunctionType<
    Types.GetFinancingFormStatusesQuery,
    Types.GetFinancingFormStatusesQueryVariables
  >;
  getFinancingSummary: SdkQueryFunctionType<
    Types.GetFinancingSummaryQuery,
    Types.GetFinancingSummaryQueryVariables
  >;
  getProposalsListSummary: SdkQueryFunctionType<
    Types.GetProposalsListSummaryQuery,
    Types.GetProposalsListSummaryQueryVariables
  >;
  getAllSecondariesRequests: SdkQueryFunctionType<
    Types.GetAllSecondariesRequestsQuery,
    Types.GetAllSecondariesRequestsQueryVariables
  >;
  getGraphAndTableForAssetType: SdkQueryFunctionType<
    Types.GetGraphAndTableForAssetTypeQuery,
    Types.GetGraphAndTableForAssetTypeQueryVariables
  >;
  getSyncStatusForAssetType: SdkQueryFunctionType<
    Types.GetSyncStatusForAssetTypeQuery,
    Types.GetSyncStatusForAssetTypeQueryVariables
  >;
  exchangePublicToken: SdkMutationFunctionType<
    Types.ExchangePublicTokenMutation,
    Types.ExchangePublicTokenMutationVariables
  >;
  getLinkToken: SdkMutationFunctionType<
    Types.GetLinkTokenMutation,
    Types.GetLinkTokenMutationVariables
  >;
  getLinkTokenForUpdate: SdkMutationFunctionType<
    Types.GetLinkTokenForUpdateMutation,
    Types.GetLinkTokenForUpdateMutationVariables
  >;
  getPortfolioPrivateEquityGraph: SdkQueryFunctionType<
    Types.GetPortfolioPrivateEquityGraphQuery,
    Types.GetPortfolioPrivateEquityGraphQueryVariables
  >;
  getInstitutionsList: SdkQueryFunctionType<
    Types.GetInstitutionsListQuery,
    Types.GetInstitutionsListQueryVariables
  >;
  unlinkInstitution: SdkMutationFunctionType<
    Types.UnlinkInstitutionMutation,
    Types.UnlinkInstitutionMutationVariables
  >;
  relinkInstitution: SdkMutationFunctionType<
    Types.RelinkInstitutionMutation,
    Types.RelinkInstitutionMutationVariables
  >;
  getAllFinancingObjects: SdkQueryFunctionType<
    Types.GetAllFinancingObjectsQuery,
    Types.GetAllFinancingObjectsQueryVariables
  >;
  getPortfolioOverview: SdkQueryFunctionType<
    Types.GetPortfolioOverviewQuery,
    Types.GetPortfolioOverviewQueryVariables
  >;
  getPortfolioOverviewAllocationTable: SdkQueryFunctionType<
    Types.GetPortfolioOverviewAllocationTableQuery,
    Types.GetPortfolioOverviewAllocationTableQueryVariables
  >;
  getPortfolioOverviewSyncStatus: SdkQueryFunctionType<
    Types.GetPortfolioOverviewSyncStatusQuery,
    Types.GetPortfolioOverviewSyncStatusQueryVariables
  >;
  personalReferralCode: SdkQueryFunctionType<
    Types.PersonalReferralCodeQuery,
    Types.PersonalReferralCodeQueryVariables
  >;
  requestAccountDeletion: SdkMutationFunctionType<
    Types.RequestAccountDeletionMutation,
    Types.RequestAccountDeletionMutationVariables
  >;
  confirmAccountDeletion: SdkMutationFunctionType<
    Types.ConfirmAccountDeletionMutation,
    Types.ConfirmAccountDeletionMutationVariables
  >;
  moveToPlanPhase: SdkMutationFunctionType<
    Types.MoveToPlanPhaseMutation,
    Types.MoveToPlanPhaseMutationVariables
  >;
  epSetExerciseLaterProjectionData: SdkMutationFunctionType<
    Types.EpSetExerciseLaterProjectionDataMutation,
    Types.EpSetExerciseLaterProjectionDataMutationVariables
  >;
  epSetExerciseNowEarlyData: SdkMutationFunctionType<
    Types.EpSetExerciseNowEarlyDataMutation,
    Types.EpSetExerciseNowEarlyDataMutationVariables
  >;
  epSelectOptionGrants: SdkMutationFunctionType<
    Types.EpSelectOptionGrantsMutation,
    Types.EpSelectOptionGrantsMutationVariables
  >;
  epSelectShareGrants: SdkMutationFunctionType<
    Types.EpSelectShareGrantsMutation,
    Types.EpSelectShareGrantsMutationVariables
  >;
  epSetCompanyAndTaxDetails: SdkMutationFunctionType<
    Types.EpSetCompanyAndTaxDetailsMutation,
    Types.EpSetCompanyAndTaxDetailsMutationVariables
  >;
  epSetExitProjection: SdkMutationFunctionType<
    Types.EpSetExitProjectionMutation,
    Types.EpSetExitProjectionMutationVariables
  >;
  updateSelectedPlanDates: SdkMutationFunctionType<
    Types.UpdateSelectedPlanDatesMutation,
    Types.UpdateSelectedPlanDatesMutationVariables
  >;
  epSetSelectedPlans: SdkMutationFunctionType<
    Types.EpSetSelectedPlansMutation,
    Types.EpSetSelectedPlansMutationVariables
  >;
  epSetTenderOfferData: SdkMutationFunctionType<
    Types.EpSetTenderOfferDataMutation,
    Types.EpSetTenderOfferDataMutationVariables
  >;
  epSetSecondaryMarketData: SdkMutationFunctionType<
    Types.EpSetSecondaryMarketDataMutation,
    Types.EpSetSecondaryMarketDataMutationVariables
  >;
  getCustomer: SdkQueryFunctionType<
    Types.GetCustomerQuery,
    Types.GetCustomerQueryVariables
  >;
  getExerciseCostsEstimation: SdkQueryFunctionType<
    Types.GetExerciseCostsEstimationQuery,
    Types.GetExerciseCostsEstimationQueryVariables
  >;
  getExitValueEstimation: SdkQueryFunctionType<
    Types.GetExitValueEstimationQuery,
    Types.GetExitValueEstimationQueryVariables
  >;
  getExitMultiplierChoices: SdkQueryFunctionType<
    Types.GetExitMultiplierChoicesQuery,
    Types.GetExitMultiplierChoicesQueryVariables
  >;
  getExitDatesChoices: SdkQueryFunctionType<
    Types.GetExitDatesChoicesQuery,
    Types.GetExitDatesChoicesQueryVariables
  >;
  getPlans: SdkQueryFunctionType<
    Types.GetPlansQuery,
    Types.GetPlansQueryVariables
  >;
  getVestedGrantsForDate: SdkQueryFunctionType<
    Types.GetVestedGrantsForDateQuery,
    Types.GetVestedGrantsForDateQueryVariables
  >;
  getAllCompanyForecasts: SdkQueryFunctionType<
    Types.GetAllCompanyForecastsQuery,
    Types.GetAllCompanyForecastsQueryVariables
  >;
  createCompanyForecast: SdkMutationFunctionType<
    Types.CreateCompanyForecastMutation,
    Types.CreateCompanyForecastMutationVariables
  >;
  updateCompanyForecast: SdkMutationFunctionType<
    Types.UpdateCompanyForecastMutation,
    Types.UpdateCompanyForecastMutationVariables
  >;
  getExitScenarioDetails: SdkQueryFunctionType<
    Types.GetExitScenarioDetailsQuery,
    Types.GetExitScenarioDetailsQueryVariables
  >;
  requestVerification: SdkMutationFunctionType<
    Types.RequestVerificationMutation,
    Types.RequestVerificationMutationVariables
  >;
  getLatestVerifiedCompanyStats: SdkQueryFunctionType<
    Types.GetLatestVerifiedCompanyStatsQuery,
    Types.GetLatestVerifiedCompanyStatsQueryVariables
  >;
  updateProposalRequestPersonalSnapshot: SdkMutationFunctionType<
    Types.UpdateProposalRequestPersonalSnapshotMutation,
    Types.UpdateProposalRequestPersonalSnapshotMutationVariables
  >;
  getAllProposalRequests: SdkQueryFunctionType<
    Types.GetAllProposalRequestsQuery,
    Types.GetAllProposalRequestsQueryVariables
  >;
  getEquityPlanTimeline: SdkQueryFunctionType<
    Types.GetEquityPlanTimelineQuery,
    Types.GetEquityPlanTimelineQueryVariables
  >;
  completePayment: SdkMutationFunctionType<
    Types.CompletePaymentMutation,
    Types.CompletePaymentMutationVariables
  >;
  endKycFlow: SdkMutationFunctionType<
    Types.EndKycFlowMutation,
    Types.EndKycFlowMutationVariables
  >;
  initKycFlow: SdkMutationFunctionType<
    Types.InitKycFlowMutation,
    Types.InitKycFlowMutationVariables
  >;
  updateAdvisoryAgreement: SdkMutationFunctionType<
    Types.UpdateAdvisoryAgreementMutation,
    Types.UpdateAdvisoryAgreementMutationVariables
  >;
  updateComplianceInformation: SdkMutationFunctionType<
    Types.UpdateComplianceInformationMutation,
    Types.UpdateComplianceInformationMutationVariables
  >;
  updatePayStubAccountStatement: SdkMutationFunctionType<
    Types.UpdatePayStubAccountStatementMutation,
    Types.UpdatePayStubAccountStatementMutationVariables
  >;
  updatePersonalInformation: SdkMutationFunctionType<
    Types.UpdatePersonalInformationMutation,
    Types.UpdatePersonalInformationMutationVariables
  >;
  updateRiskAssessment: SdkMutationFunctionType<
    Types.UpdateRiskAssessmentMutation,
    Types.UpdateRiskAssessmentMutationVariables
  >;
  getRiaComplianceInformation: SdkQueryFunctionType<
    Types.GetRiaComplianceInformationQuery,
    Types.GetRiaComplianceInformationQueryVariables
  >;
  getRiaIdentificationInformation: SdkQueryFunctionType<
    Types.GetRiaIdentificationInformationQuery,
    Types.GetRiaIdentificationInformationQueryVariables
  >;
  getPayStubAccountStatement: SdkQueryFunctionType<
    Types.GetPayStubAccountStatementQuery,
    Types.GetPayStubAccountStatementQueryVariables
  >;
  getRiaPersonalInformation: SdkQueryFunctionType<
    Types.GetRiaPersonalInformationQuery,
    Types.GetRiaPersonalInformationQueryVariables
  >;
  getRiskAssessment: SdkQueryFunctionType<
    Types.GetRiskAssessmentQuery,
    Types.GetRiskAssessmentQueryVariables
  >;
  createRiaDeals: SdkMutationFunctionType<
    Types.CreateRiaDealsMutation,
    Types.CreateRiaDealsMutationVariables
  >;
  moveAdvisoryCustomerToWealth: SdkMutationFunctionType<
    Types.MoveAdvisoryCustomerToWealthMutation,
    Types.MoveAdvisoryCustomerToWealthMutationVariables
  >;
  moveToStatus: SdkMutationFunctionType<
    Types.MoveToStatusMutation,
    Types.MoveToStatusMutationVariables
  >;
  getCustomerActiveRiaDeal: SdkQueryFunctionType<
    Types.GetCustomerActiveRiaDealQuery,
    Types.GetCustomerActiveRiaDealQueryVariables
  >;
  addCustomerToWaitlist: SdkMutationFunctionType<
    Types.AddCustomerToWaitlistMutation,
    Types.AddCustomerToWaitlistMutationVariables
  >;
  getWaitlists: SdkQueryFunctionType<
    Types.GetWaitlistsQuery,
    Types.GetWaitlistsQueryVariables
  >;
  searchCompanies: SdkQueryFunctionType<
    Types.SearchCompaniesQuery,
    Types.SearchCompaniesQueryVariables
  >;
  selectNewCompany: SdkMutationFunctionType<
    Types.SelectNewCompanyMutation,
    Types.SelectNewCompanyMutationVariables
  >;
  getCompanyStatusAndSubstatus: SdkQueryFunctionType<
    Types.GetCompanyStatusAndSubstatusQuery,
    Types.GetCompanyStatusAndSubstatusQueryVariables
  >;
  createScheduledAppointment: SdkMutationFunctionType<
    Types.CreateScheduledAppointmentMutation,
    Types.CreateScheduledAppointmentMutationVariables
  >;
  getScheduledAppointments: SdkQueryFunctionType<
    Types.GetScheduledAppointmentsQuery,
    Types.GetScheduledAppointmentsQueryVariables
  >;
  updateScheduledAppointment: SdkMutationFunctionType<
    Types.UpdateScheduledAppointmentMutation,
    Types.UpdateScheduledAppointmentMutationVariables
  >;
  createExerciseEvent: SdkMutationFunctionType<
    Types.CreateExerciseEventMutation,
    Types.CreateExerciseEventMutationVariables
  >;
  createRsuGrant: SdkMutationFunctionType<
    Types.CreateRsuGrantMutation,
    Types.CreateRsuGrantMutationVariables
  >;
  createEquityShareGrant: SdkMutationFunctionType<
    Types.CreateEquityShareGrantMutation,
    Types.CreateEquityShareGrantMutationVariables
  >;
  createStockOptionGrant: SdkMutationFunctionType<
    Types.CreateStockOptionGrantMutation,
    Types.CreateStockOptionGrantMutationVariables
  >;
  deleteExerciseEvent: SdkMutationFunctionType<
    Types.DeleteExerciseEventMutation,
    Types.DeleteExerciseEventMutationVariables
  >;
  deleteRsuGrant: SdkMutationFunctionType<
    Types.DeleteRsuGrantMutation,
    Types.DeleteRsuGrantMutationVariables
  >;
  deleteEquityShareGrant: SdkMutationFunctionType<
    Types.DeleteEquityShareGrantMutation,
    Types.DeleteEquityShareGrantMutationVariables
  >;
  deleteStockOptionGrant: SdkMutationFunctionType<
    Types.DeleteStockOptionGrantMutation,
    Types.DeleteStockOptionGrantMutationVariables
  >;
  updateRsuGrant: SdkMutationFunctionType<
    Types.UpdateRsuGrantMutation,
    Types.UpdateRsuGrantMutationVariables
  >;
  updateEquityShareGrant: SdkMutationFunctionType<
    Types.UpdateEquityShareGrantMutation,
    Types.UpdateEquityShareGrantMutationVariables
  >;
  updateStockOptionGrant: SdkMutationFunctionType<
    Types.UpdateStockOptionGrantMutation,
    Types.UpdateStockOptionGrantMutationVariables
  >;
  updateExerciseEvent: SdkMutationFunctionType<
    Types.UpdateExerciseEventMutation,
    Types.UpdateExerciseEventMutationVariables
  >;
  calculateVestedOptions: SdkQueryFunctionType<
    Types.CalculateVestedOptionsQuery,
    Types.CalculateVestedOptionsQueryVariables
  >;
  getAllGrants: SdkQueryFunctionType<
    Types.GetAllGrantsQuery,
    Types.GetAllGrantsQueryVariables
  >;
  getCartaConnectionStatus: SdkQueryFunctionType<
    Types.GetCartaConnectionStatusQuery,
    Types.GetCartaConnectionStatusQueryVariables
  >;
  getCartaLoginUrl: SdkQueryFunctionType<
    Types.GetCartaLoginUrlQuery,
    Types.GetCartaLoginUrlQueryVariables
  >;
  getEquityShareGrant: SdkQueryFunctionType<
    Types.GetEquityShareGrantQuery,
    Types.GetEquityShareGrantQueryVariables
  >;
  getRsuGrant: SdkQueryFunctionType<
    Types.GetRsuGrantQuery,
    Types.GetRsuGrantQueryVariables
  >;
  getRsuGrants: SdkQueryFunctionType<
    Types.GetRsuGrantsQuery,
    Types.GetRsuGrantsQueryVariables
  >;
  getShareGrants: SdkQueryFunctionType<
    Types.GetShareGrantsQuery,
    Types.GetShareGrantsQueryVariables
  >;
  getStockOptionGrant: SdkQueryFunctionType<
    Types.GetStockOptionGrantQuery,
    Types.GetStockOptionGrantQueryVariables
  >;
  getStockOptionGrants: SdkQueryFunctionType<
    Types.GetStockOptionGrantsQuery,
    Types.GetStockOptionGrantsQueryVariables
  >;
  importCartaData: SdkMutationFunctionType<
    Types.ImportCartaDataMutation,
    Types.ImportCartaDataMutationVariables
  >;
  getCompanyNameByAffiliation: SdkQueryFunctionType<
    Types.GetCompanyNameByAffiliationQuery,
    Types.GetCompanyNameByAffiliationQueryVariables
  >;
  createOrUpdateUsTaxInformation: SdkMutationFunctionType<
    Types.CreateOrUpdateUsTaxInformationMutation,
    Types.CreateOrUpdateUsTaxInformationMutationVariables
  >;
  getAllUsTaxInfo: SdkQueryFunctionType<
    Types.GetAllUsTaxInfoQuery,
    Types.GetAllUsTaxInfoQueryVariables
  >;
  deleteAffiliation: SdkMutationFunctionType<
    Types.DeleteAffiliationMutation,
    Types.DeleteAffiliationMutationVariables
  >;
  validateEmploymentEndDate: SdkQueryFunctionType<
    Types.ValidateEmploymentEndDateQuery,
    Types.ValidateEmploymentEndDateQueryVariables
  >;
  createAffiliation: SdkMutationFunctionType<
    Types.CreateAffiliationMutation,
    Types.CreateAffiliationMutationVariables
  >;
  disconnectCartaAccount: SdkMutationFunctionType<
    Types.DisconnectCartaAccountMutation,
    Types.DisconnectCartaAccountMutationVariables
  >;
  setCustomerFlag: SdkMutationFunctionType<
    Types.SetCustomerFlagMutation,
    Types.SetCustomerFlagMutationVariables
  >;
  signInWithSignInToken: SdkMutationFunctionType<
    Types.SignInWithSignInTokenMutation,
    Types.SignInWithSignInTokenMutationVariables
  >;
  updateAffiliationDetails: SdkMutationFunctionType<
    Types.UpdateAffiliationDetailsMutation,
    Types.UpdateAffiliationDetailsMutationVariables
  >;
  updateCustomer: SdkMutationFunctionType<
    Types.UpdateCustomerMutation,
    Types.UpdateCustomerMutationVariables
  >;
  getAffiliationDetails: SdkQueryFunctionType<
    Types.GetAffiliationDetailsQuery,
    Types.GetAffiliationDetailsQueryVariables
  >;
  getAffiliationOverview: SdkQueryFunctionType<
    Types.GetAffiliationOverviewQuery,
    Types.GetAffiliationOverviewQueryVariables
  >;
  getAllAffiliationOverview: SdkQueryFunctionType<
    Types.GetAllAffiliationOverviewQuery,
    Types.GetAllAffiliationOverviewQueryVariables
  >;
  getCurrentPublicPrices: SdkQueryFunctionType<
    Types.GetCurrentPublicPricesQuery,
    Types.GetCurrentPublicPricesQueryVariables
  >;
  getCustomerFlag: SdkQueryFunctionType<
    Types.GetCustomerFlagQuery,
    Types.GetCustomerFlagQueryVariables
  >;
  getCustomersByUser: SdkQueryFunctionType<
    Types.GetCustomersByUserQuery,
    Types.GetCustomersByUserQueryVariables
  >;
  getFormOptions: SdkQueryFunctionType<
    Types.GetFormOptionsQuery,
    Types.GetFormOptionsQueryVariables
  >;
  getTotalNetWorth: SdkQueryFunctionType<
    Types.GetTotalNetWorthQuery,
    Types.GetTotalNetWorthQueryVariables
  >;
  getUser: SdkQueryFunctionType<
    Types.GetUserQuery,
    Types.GetUserQueryVariables
  >;
  fetchClientChecklist: SdkQueryFunctionType<
    Types.FetchClientChecklistQuery,
    Types.FetchClientChecklistQueryVariables
  >;
}

export const createApolloSdk = (
  client: ApolloClient<NormalizedCacheObject>
) => {
  const sdk: SecfiApolloSdk = {
    latestPublicPriceForDate: (variables) =>
      client.query<
        Types.LatestPublicPriceForDateQuery,
        Types.LatestPublicPriceForDateQueryVariables
      >({ query: Operations.LatestPublicPriceForDateDocument, variables }),
    processCartaSignupCallback: (variables) =>
      client.mutate<
        Types.ProcessCartaSignupCallbackMutation,
        Types.ProcessCartaSignupCallbackMutationVariables
      >({ mutation: Operations.ProcessCartaSignupCallbackDocument, variables }),
    createAccountPasswordless: (variables) =>
      client.mutate<
        Types.CreateAccountPasswordlessMutation,
        Types.CreateAccountPasswordlessMutationVariables
      >({ mutation: Operations.CreateAccountPasswordlessDocument, variables }),
    userIdentification: (variables) =>
      client.query<
        Types.UserIdentificationQuery,
        Types.UserIdentificationQueryVariables
      >({ query: Operations.UserIdentificationDocument, variables }),
    getMockProposal: (variables) =>
      client.query<
        Types.GetMockProposalQuery,
        Types.GetMockProposalQueryVariables
      >({ query: Operations.GetMockProposalDocument, variables }),
    getBankAccountInformation: (variables) =>
      client.query<
        Types.GetBankAccountInformationQuery,
        Types.GetBankAccountInformationQueryVariables
      >({ query: Operations.GetBankAccountInformationDocument, variables }),
    createOrUpdateBankAccountInformation: (variables) =>
      client.mutate<
        Types.CreateOrUpdateBankAccountInformationMutation,
        Types.CreateOrUpdateBankAccountInformationMutationVariables
      >({
        mutation: Operations.CreateOrUpdateBankAccountInformationDocument,
        variables,
      }),
    getCustomerBankAccountInformation: (variables) =>
      client.query<
        Types.GetCustomerBankAccountInformationQuery,
        Types.GetCustomerBankAccountInformationQueryVariables
      >({
        query: Operations.GetCustomerBankAccountInformationDocument,
        variables,
      }),
    createOrUpdateCustomerBankAccountInformation: (variables) =>
      client.mutate<
        Types.CreateOrUpdateCustomerBankAccountInformationMutation,
        Types.CreateOrUpdateCustomerBankAccountInformationMutationVariables
      >({
        mutation:
          Operations.CreateOrUpdateCustomerBankAccountInformationDocument,
        variables,
      }),
    getComplianceInformation: (variables) =>
      client.query<
        Types.GetComplianceInformationQuery,
        Types.GetComplianceInformationQueryVariables
      >({ query: Operations.GetComplianceInformationDocument, variables }),
    createOrUpdateComplianceInformation: (variables) =>
      client.mutate<
        Types.CreateOrUpdateComplianceInformationMutation,
        Types.CreateOrUpdateComplianceInformationMutationVariables
      >({
        mutation: Operations.CreateOrUpdateComplianceInformationDocument,
        variables,
      }),
    getEquityVerification: (variables) =>
      client.query<
        Types.GetEquityVerificationQuery,
        Types.GetEquityVerificationQueryVariables
      >({ query: Operations.GetEquityVerificationDocument, variables }),
    createOrUpdateEquityVerification: (variables) =>
      client.mutate<
        Types.CreateOrUpdateEquityVerificationMutation,
        Types.CreateOrUpdateEquityVerificationMutationVariables
      >({
        mutation: Operations.CreateOrUpdateEquityVerificationDocument,
        variables,
      }),
    getFinancingOptionGrants: (variables) =>
      client.query<
        Types.GetFinancingOptionGrantsQuery,
        Types.GetFinancingOptionGrantsQueryVariables
      >({ query: Operations.GetFinancingOptionGrantsDocument, variables }),
    updateFinancingOptionGrant: (variables) =>
      client.mutate<
        Types.UpdateFinancingOptionGrantMutation,
        Types.UpdateFinancingOptionGrantMutationVariables
      >({ mutation: Operations.UpdateFinancingOptionGrantDocument, variables }),
    getFinancingShareGrants: (variables) =>
      client.query<
        Types.GetFinancingShareGrantsQuery,
        Types.GetFinancingShareGrantsQueryVariables
      >({ query: Operations.GetFinancingShareGrantsDocument, variables }),
    updateFinancingShareGrant: (variables) =>
      client.mutate<
        Types.UpdateFinancingShareGrantMutation,
        Types.UpdateFinancingShareGrantMutationVariables
      >({ mutation: Operations.UpdateFinancingShareGrantDocument, variables }),
    getIdentificationInformation: (variables) =>
      client.query<
        Types.GetIdentificationInformationQuery,
        Types.GetIdentificationInformationQueryVariables
      >({ query: Operations.GetIdentificationInformationDocument, variables }),
    createOrUpdateIdentificationInformation: (variables) =>
      client.mutate<
        Types.CreateOrUpdateIdentificationInformationMutation,
        Types.CreateOrUpdateIdentificationInformationMutationVariables
      >({
        mutation: Operations.CreateOrUpdateIdentificationInformationDocument,
        variables,
      }),
    startKycVerification: (variables) =>
      client.mutate<
        Types.StartKycVerificationMutation,
        Types.StartKycVerificationMutationVariables
      >({ mutation: Operations.StartKycVerificationDocument, variables }),
    endKycVerification: (variables) =>
      client.mutate<
        Types.EndKycVerificationMutation,
        Types.EndKycVerificationMutationVariables
      >({ mutation: Operations.EndKycVerificationDocument, variables }),
    getIntermediaryServicesAgreement: (variables) =>
      client.query<
        Types.GetIntermediaryServicesAgreementQuery,
        Types.GetIntermediaryServicesAgreementQueryVariables
      >({
        query: Operations.GetIntermediaryServicesAgreementDocument,
        variables,
      }),
    getPersonalInformation: (variables) =>
      client.query<
        Types.GetPersonalInformationQuery,
        Types.GetPersonalInformationQueryVariables
      >({ query: Operations.GetPersonalInformationDocument, variables }),
    getPersonalInformationCitizenshipOptions: (variables) =>
      client.query<
        Types.GetPersonalInformationCitizenshipOptionsQuery,
        Types.GetPersonalInformationCitizenshipOptionsQueryVariables
      >({
        query: Operations.GetPersonalInformationCitizenshipOptionsDocument,
        variables,
      }),
    createOrUpdatePersonalInformation: (variables) =>
      client.mutate<
        Types.CreateOrUpdatePersonalInformationMutation,
        Types.CreateOrUpdatePersonalInformationMutationVariables
      >({
        mutation: Operations.CreateOrUpdatePersonalInformationDocument,
        variables,
      }),
    getTaxWithholdings: (variables) =>
      client.query<
        Types.GetTaxWithholdingsQuery,
        Types.GetTaxWithholdingsQueryVariables
      >({ query: Operations.GetTaxWithholdingsDocument, variables }),
    updateTaxWithholdings: (variables) =>
      client.mutate<
        Types.UpdateTaxWithholdingsMutation,
        Types.UpdateTaxWithholdingsMutationVariables
      >({ mutation: Operations.UpdateTaxWithholdingsDocument, variables }),
    updateTaxWithholdingsOptionRelation: (variables) =>
      client.mutate<
        Types.UpdateTaxWithholdingsOptionRelationMutation,
        Types.UpdateTaxWithholdingsOptionRelationMutationVariables
      >({
        mutation: Operations.UpdateTaxWithholdingsOptionRelationDocument,
        variables,
      }),
    getW9Form: (variables) =>
      client.query<Types.GetW9FormQuery, Types.GetW9FormQueryVariables>({
        query: Operations.GetW9FormDocument,
        variables,
      }),
    requestProposalRegeneration: (variables) =>
      client.mutate<
        Types.RequestProposalRegenerationMutation,
        Types.RequestProposalRegenerationMutationVariables
      >({
        mutation: Operations.RequestProposalRegenerationDocument,
        variables,
      }),
    confirmAcceptProposal: (variables) =>
      client.mutate<
        Types.ConfirmAcceptProposalMutation,
        Types.ConfirmAcceptProposalMutationVariables
      >({ mutation: Operations.ConfirmAcceptProposalDocument, variables }),
    createProposalRequest: (variables) =>
      client.mutate<
        Types.CreateProposalRequestMutation,
        Types.CreateProposalRequestMutationVariables
      >({ mutation: Operations.CreateProposalRequestDocument, variables }),
    initContractSigning: (variables) =>
      client.mutate<
        Types.InitContractSigningMutation,
        Types.InitContractSigningMutationVariables
      >({ mutation: Operations.InitContractSigningDocument, variables }),
    processScheduledCall: (variables) =>
      client.mutate<
        Types.ProcessScheduledCallMutation,
        Types.ProcessScheduledCallMutationVariables
      >({ mutation: Operations.ProcessScheduledCallDocument, variables }),
    submitFinancingOnboardingForm: (variables) =>
      client.mutate<
        Types.SubmitFinancingOnboardingFormMutation,
        Types.SubmitFinancingOnboardingFormMutationVariables
      >({
        mutation: Operations.SubmitFinancingOnboardingFormDocument,
        variables,
      }),
    updateProposalRequest: (variables) =>
      client.mutate<
        Types.UpdateProposalRequestMutation,
        Types.UpdateProposalRequestMutationVariables
      >({ mutation: Operations.UpdateProposalRequestDocument, variables }),
    getSeriesContract: (variables) =>
      client.query<
        Types.GetSeriesContractQuery,
        Types.GetSeriesContractQueryVariables
      >({ query: Operations.GetSeriesContractDocument, variables }),
    getInvestmentDirectionLetter: (variables) =>
      client.query<
        Types.GetInvestmentDirectionLetterQuery,
        Types.GetInvestmentDirectionLetterQueryVariables
      >({ query: Operations.GetInvestmentDirectionLetterDocument, variables }),
    getSpringingLienAssignmentAgreement: (variables) =>
      client.query<
        Types.GetSpringingLienAssignmentAgreementQuery,
        Types.GetSpringingLienAssignmentAgreementQueryVariables
      >({
        query: Operations.GetSpringingLienAssignmentAgreementDocument,
        variables,
      }),
    getSpringingLienPsc: (variables) =>
      client.query<
        Types.GetSpringingLienPscQuery,
        Types.GetSpringingLienPscQueryVariables
      >({ query: Operations.GetSpringingLienPscDocument, variables }),
    getSpringingLienStockPledge: (variables) =>
      client.query<
        Types.GetSpringingLienStockPledgeQuery,
        Types.GetSpringingLienStockPledgeQueryVariables
      >({ query: Operations.GetSpringingLienStockPledgeDocument, variables }),
    getPrivateSecuritiesContract: (variables) =>
      client.query<
        Types.GetPrivateSecuritiesContractQuery,
        Types.GetPrivateSecuritiesContractQueryVariables
      >({ query: Operations.GetPrivateSecuritiesContractDocument, variables }),
    getFinancingCategoryStatuses: (variables) =>
      client.query<
        Types.GetFinancingCategoryStatusesQuery,
        Types.GetFinancingCategoryStatusesQueryVariables
      >({ query: Operations.GetFinancingCategoryStatusesDocument, variables }),
    getProposal: (variables) =>
      client.query<Types.GetProposalQuery, Types.GetProposalQueryVariables>({
        query: Operations.GetProposalDocument,
        variables,
      }),
    getProposalExitComparison: (variables) =>
      client.query<
        Types.GetProposalExitComparisonQuery,
        Types.GetProposalExitComparisonQueryVariables
      >({ query: Operations.GetProposalExitComparisonDocument, variables }),
    getProposalRequest: (variables) =>
      client.query<
        Types.GetProposalRequestQuery,
        Types.GetProposalRequestQueryVariables
      >({ query: Operations.GetProposalRequestDocument, variables }),
    getProposalTaxDetails: (variables) =>
      client.query<
        Types.GetProposalTaxDetailsQuery,
        Types.GetProposalTaxDetailsQueryVariables
      >({ query: Operations.GetProposalTaxDetailsDocument, variables }),
    addSecondariesRequest: (variables) =>
      client.mutate<
        Types.AddSecondariesRequestMutation,
        Types.AddSecondariesRequestMutationVariables
      >({ mutation: Operations.AddSecondariesRequestDocument, variables }),
    updateFinancingObject: (variables) =>
      client.mutate<
        Types.UpdateFinancingObjectMutation,
        Types.UpdateFinancingObjectMutationVariables
      >({ mutation: Operations.UpdateFinancingObjectDocument, variables }),
    updateSecondariesRequest: (variables) =>
      client.mutate<
        Types.UpdateSecondariesRequestMutation,
        Types.UpdateSecondariesRequestMutationVariables
      >({ mutation: Operations.UpdateSecondariesRequestDocument, variables }),
    getFinancingBasicInfo: (variables) =>
      client.query<
        Types.GetFinancingBasicInfoQuery,
        Types.GetFinancingBasicInfoQueryVariables
      >({ query: Operations.GetFinancingBasicInfoDocument, variables }),
    getFinancingFormStatuses: (variables) =>
      client.query<
        Types.GetFinancingFormStatusesQuery,
        Types.GetFinancingFormStatusesQueryVariables
      >({ query: Operations.GetFinancingFormStatusesDocument, variables }),
    getFinancingSummary: (variables) =>
      client.query<
        Types.GetFinancingSummaryQuery,
        Types.GetFinancingSummaryQueryVariables
      >({ query: Operations.GetFinancingSummaryDocument, variables }),
    getProposalsListSummary: (variables) =>
      client.query<
        Types.GetProposalsListSummaryQuery,
        Types.GetProposalsListSummaryQueryVariables
      >({ query: Operations.GetProposalsListSummaryDocument, variables }),
    getAllSecondariesRequests: (variables) =>
      client.query<
        Types.GetAllSecondariesRequestsQuery,
        Types.GetAllSecondariesRequestsQueryVariables
      >({ query: Operations.GetAllSecondariesRequestsDocument, variables }),
    getGraphAndTableForAssetType: (variables) =>
      client.query<
        Types.GetGraphAndTableForAssetTypeQuery,
        Types.GetGraphAndTableForAssetTypeQueryVariables
      >({ query: Operations.GetGraphAndTableForAssetTypeDocument, variables }),
    getSyncStatusForAssetType: (variables) =>
      client.query<
        Types.GetSyncStatusForAssetTypeQuery,
        Types.GetSyncStatusForAssetTypeQueryVariables
      >({ query: Operations.GetSyncStatusForAssetTypeDocument, variables }),
    exchangePublicToken: (variables) =>
      client.mutate<
        Types.ExchangePublicTokenMutation,
        Types.ExchangePublicTokenMutationVariables
      >({ mutation: Operations.ExchangePublicTokenDocument, variables }),
    getLinkToken: (variables) =>
      client.mutate<
        Types.GetLinkTokenMutation,
        Types.GetLinkTokenMutationVariables
      >({ mutation: Operations.GetLinkTokenDocument, variables }),
    getLinkTokenForUpdate: (variables) =>
      client.mutate<
        Types.GetLinkTokenForUpdateMutation,
        Types.GetLinkTokenForUpdateMutationVariables
      >({ mutation: Operations.GetLinkTokenForUpdateDocument, variables }),
    getPortfolioPrivateEquityGraph: (variables) =>
      client.query<
        Types.GetPortfolioPrivateEquityGraphQuery,
        Types.GetPortfolioPrivateEquityGraphQueryVariables
      >({
        query: Operations.GetPortfolioPrivateEquityGraphDocument,
        variables,
      }),
    getInstitutionsList: (variables) =>
      client.query<
        Types.GetInstitutionsListQuery,
        Types.GetInstitutionsListQueryVariables
      >({ query: Operations.GetInstitutionsListDocument, variables }),
    unlinkInstitution: (variables) =>
      client.mutate<
        Types.UnlinkInstitutionMutation,
        Types.UnlinkInstitutionMutationVariables
      >({ mutation: Operations.UnlinkInstitutionDocument, variables }),
    relinkInstitution: (variables) =>
      client.mutate<
        Types.RelinkInstitutionMutation,
        Types.RelinkInstitutionMutationVariables
      >({ mutation: Operations.RelinkInstitutionDocument, variables }),
    getAllFinancingObjects: (variables) =>
      client.query<
        Types.GetAllFinancingObjectsQuery,
        Types.GetAllFinancingObjectsQueryVariables
      >({ query: Operations.GetAllFinancingObjectsDocument, variables }),
    getPortfolioOverview: (variables) =>
      client.query<
        Types.GetPortfolioOverviewQuery,
        Types.GetPortfolioOverviewQueryVariables
      >({ query: Operations.GetPortfolioOverviewDocument, variables }),
    getPortfolioOverviewAllocationTable: (variables) =>
      client.query<
        Types.GetPortfolioOverviewAllocationTableQuery,
        Types.GetPortfolioOverviewAllocationTableQueryVariables
      >({
        query: Operations.GetPortfolioOverviewAllocationTableDocument,
        variables,
      }),
    getPortfolioOverviewSyncStatus: (variables) =>
      client.query<
        Types.GetPortfolioOverviewSyncStatusQuery,
        Types.GetPortfolioOverviewSyncStatusQueryVariables
      >({
        query: Operations.GetPortfolioOverviewSyncStatusDocument,
        variables,
      }),
    personalReferralCode: (variables) =>
      client.query<
        Types.PersonalReferralCodeQuery,
        Types.PersonalReferralCodeQueryVariables
      >({ query: Operations.PersonalReferralCodeDocument, variables }),
    requestAccountDeletion: (variables) =>
      client.mutate<
        Types.RequestAccountDeletionMutation,
        Types.RequestAccountDeletionMutationVariables
      >({ mutation: Operations.RequestAccountDeletionDocument, variables }),
    confirmAccountDeletion: (variables) =>
      client.mutate<
        Types.ConfirmAccountDeletionMutation,
        Types.ConfirmAccountDeletionMutationVariables
      >({ mutation: Operations.ConfirmAccountDeletionDocument, variables }),
    moveToPlanPhase: (variables) =>
      client.mutate<
        Types.MoveToPlanPhaseMutation,
        Types.MoveToPlanPhaseMutationVariables
      >({ mutation: Operations.MoveToPlanPhaseDocument, variables }),
    epSetExerciseLaterProjectionData: (variables) =>
      client.mutate<
        Types.EpSetExerciseLaterProjectionDataMutation,
        Types.EpSetExerciseLaterProjectionDataMutationVariables
      >({
        mutation: Operations.EpSetExerciseLaterProjectionDataDocument,
        variables,
      }),
    epSetExerciseNowEarlyData: (variables) =>
      client.mutate<
        Types.EpSetExerciseNowEarlyDataMutation,
        Types.EpSetExerciseNowEarlyDataMutationVariables
      >({ mutation: Operations.EpSetExerciseNowEarlyDataDocument, variables }),
    epSelectOptionGrants: (variables) =>
      client.mutate<
        Types.EpSelectOptionGrantsMutation,
        Types.EpSelectOptionGrantsMutationVariables
      >({ mutation: Operations.EpSelectOptionGrantsDocument, variables }),
    epSelectShareGrants: (variables) =>
      client.mutate<
        Types.EpSelectShareGrantsMutation,
        Types.EpSelectShareGrantsMutationVariables
      >({ mutation: Operations.EpSelectShareGrantsDocument, variables }),
    epSetCompanyAndTaxDetails: (variables) =>
      client.mutate<
        Types.EpSetCompanyAndTaxDetailsMutation,
        Types.EpSetCompanyAndTaxDetailsMutationVariables
      >({ mutation: Operations.EpSetCompanyAndTaxDetailsDocument, variables }),
    epSetExitProjection: (variables) =>
      client.mutate<
        Types.EpSetExitProjectionMutation,
        Types.EpSetExitProjectionMutationVariables
      >({ mutation: Operations.EpSetExitProjectionDocument, variables }),
    updateSelectedPlanDates: (variables) =>
      client.mutate<
        Types.UpdateSelectedPlanDatesMutation,
        Types.UpdateSelectedPlanDatesMutationVariables
      >({ mutation: Operations.UpdateSelectedPlanDatesDocument, variables }),
    epSetSelectedPlans: (variables) =>
      client.mutate<
        Types.EpSetSelectedPlansMutation,
        Types.EpSetSelectedPlansMutationVariables
      >({ mutation: Operations.EpSetSelectedPlansDocument, variables }),
    epSetTenderOfferData: (variables) =>
      client.mutate<
        Types.EpSetTenderOfferDataMutation,
        Types.EpSetTenderOfferDataMutationVariables
      >({ mutation: Operations.EpSetTenderOfferDataDocument, variables }),
    epSetSecondaryMarketData: (variables) =>
      client.mutate<
        Types.EpSetSecondaryMarketDataMutation,
        Types.EpSetSecondaryMarketDataMutationVariables
      >({ mutation: Operations.EpSetSecondaryMarketDataDocument, variables }),
    getCustomer: (variables) =>
      client.query<Types.GetCustomerQuery, Types.GetCustomerQueryVariables>({
        query: Operations.GetCustomerDocument,
        variables,
      }),
    getExerciseCostsEstimation: (variables) =>
      client.query<
        Types.GetExerciseCostsEstimationQuery,
        Types.GetExerciseCostsEstimationQueryVariables
      >({ query: Operations.GetExerciseCostsEstimationDocument, variables }),
    getExitValueEstimation: (variables) =>
      client.query<
        Types.GetExitValueEstimationQuery,
        Types.GetExitValueEstimationQueryVariables
      >({ query: Operations.GetExitValueEstimationDocument, variables }),
    getExitMultiplierChoices: (variables) =>
      client.query<
        Types.GetExitMultiplierChoicesQuery,
        Types.GetExitMultiplierChoicesQueryVariables
      >({ query: Operations.GetExitMultiplierChoicesDocument, variables }),
    getExitDatesChoices: (variables) =>
      client.query<
        Types.GetExitDatesChoicesQuery,
        Types.GetExitDatesChoicesQueryVariables
      >({ query: Operations.GetExitDatesChoicesDocument, variables }),
    getPlans: (variables) =>
      client.query<Types.GetPlansQuery, Types.GetPlansQueryVariables>({
        query: Operations.GetPlansDocument,
        variables,
      }),
    getVestedGrantsForDate: (variables) =>
      client.query<
        Types.GetVestedGrantsForDateQuery,
        Types.GetVestedGrantsForDateQueryVariables
      >({ query: Operations.GetVestedGrantsForDateDocument, variables }),
    getAllCompanyForecasts: (variables) =>
      client.query<
        Types.GetAllCompanyForecastsQuery,
        Types.GetAllCompanyForecastsQueryVariables
      >({ query: Operations.GetAllCompanyForecastsDocument, variables }),
    createCompanyForecast: (variables) =>
      client.mutate<
        Types.CreateCompanyForecastMutation,
        Types.CreateCompanyForecastMutationVariables
      >({ mutation: Operations.CreateCompanyForecastDocument, variables }),
    updateCompanyForecast: (variables) =>
      client.mutate<
        Types.UpdateCompanyForecastMutation,
        Types.UpdateCompanyForecastMutationVariables
      >({ mutation: Operations.UpdateCompanyForecastDocument, variables }),
    getExitScenarioDetails: (variables) =>
      client.query<
        Types.GetExitScenarioDetailsQuery,
        Types.GetExitScenarioDetailsQueryVariables
      >({ query: Operations.GetExitScenarioDetailsDocument, variables }),
    requestVerification: (variables) =>
      client.mutate<
        Types.RequestVerificationMutation,
        Types.RequestVerificationMutationVariables
      >({ mutation: Operations.RequestVerificationDocument, variables }),
    getLatestVerifiedCompanyStats: (variables) =>
      client.query<
        Types.GetLatestVerifiedCompanyStatsQuery,
        Types.GetLatestVerifiedCompanyStatsQueryVariables
      >({ query: Operations.GetLatestVerifiedCompanyStatsDocument, variables }),
    updateProposalRequestPersonalSnapshot: (variables) =>
      client.mutate<
        Types.UpdateProposalRequestPersonalSnapshotMutation,
        Types.UpdateProposalRequestPersonalSnapshotMutationVariables
      >({
        mutation: Operations.UpdateProposalRequestPersonalSnapshotDocument,
        variables,
      }),
    getAllProposalRequests: (variables) =>
      client.query<
        Types.GetAllProposalRequestsQuery,
        Types.GetAllProposalRequestsQueryVariables
      >({ query: Operations.GetAllProposalRequestsDocument, variables }),
    getEquityPlanTimeline: (variables) =>
      client.query<
        Types.GetEquityPlanTimelineQuery,
        Types.GetEquityPlanTimelineQueryVariables
      >({ query: Operations.GetEquityPlanTimelineDocument, variables }),
    completePayment: (variables) =>
      client.mutate<
        Types.CompletePaymentMutation,
        Types.CompletePaymentMutationVariables
      >({ mutation: Operations.CompletePaymentDocument, variables }),
    endKycFlow: (variables) =>
      client.mutate<
        Types.EndKycFlowMutation,
        Types.EndKycFlowMutationVariables
      >({ mutation: Operations.EndKycFlowDocument, variables }),
    initKycFlow: (variables) =>
      client.mutate<
        Types.InitKycFlowMutation,
        Types.InitKycFlowMutationVariables
      >({ mutation: Operations.InitKycFlowDocument, variables }),
    updateAdvisoryAgreement: (variables) =>
      client.mutate<
        Types.UpdateAdvisoryAgreementMutation,
        Types.UpdateAdvisoryAgreementMutationVariables
      >({ mutation: Operations.UpdateAdvisoryAgreementDocument, variables }),
    updateComplianceInformation: (variables) =>
      client.mutate<
        Types.UpdateComplianceInformationMutation,
        Types.UpdateComplianceInformationMutationVariables
      >({
        mutation: Operations.UpdateComplianceInformationDocument,
        variables,
      }),
    updatePayStubAccountStatement: (variables) =>
      client.mutate<
        Types.UpdatePayStubAccountStatementMutation,
        Types.UpdatePayStubAccountStatementMutationVariables
      >({
        mutation: Operations.UpdatePayStubAccountStatementDocument,
        variables,
      }),
    updatePersonalInformation: (variables) =>
      client.mutate<
        Types.UpdatePersonalInformationMutation,
        Types.UpdatePersonalInformationMutationVariables
      >({ mutation: Operations.UpdatePersonalInformationDocument, variables }),
    updateRiskAssessment: (variables) =>
      client.mutate<
        Types.UpdateRiskAssessmentMutation,
        Types.UpdateRiskAssessmentMutationVariables
      >({ mutation: Operations.UpdateRiskAssessmentDocument, variables }),
    getRiaComplianceInformation: (variables) =>
      client.query<
        Types.GetRiaComplianceInformationQuery,
        Types.GetRiaComplianceInformationQueryVariables
      >({ query: Operations.GetRiaComplianceInformationDocument, variables }),
    getRiaIdentificationInformation: (variables) =>
      client.query<
        Types.GetRiaIdentificationInformationQuery,
        Types.GetRiaIdentificationInformationQueryVariables
      >({
        query: Operations.GetRiaIdentificationInformationDocument,
        variables,
      }),
    getPayStubAccountStatement: (variables) =>
      client.query<
        Types.GetPayStubAccountStatementQuery,
        Types.GetPayStubAccountStatementQueryVariables
      >({ query: Operations.GetPayStubAccountStatementDocument, variables }),
    getRiaPersonalInformation: (variables) =>
      client.query<
        Types.GetRiaPersonalInformationQuery,
        Types.GetRiaPersonalInformationQueryVariables
      >({ query: Operations.GetRiaPersonalInformationDocument, variables }),
    getRiskAssessment: (variables) =>
      client.query<
        Types.GetRiskAssessmentQuery,
        Types.GetRiskAssessmentQueryVariables
      >({ query: Operations.GetRiskAssessmentDocument, variables }),
    createRiaDeals: (variables) =>
      client.mutate<
        Types.CreateRiaDealsMutation,
        Types.CreateRiaDealsMutationVariables
      >({ mutation: Operations.CreateRiaDealsDocument, variables }),
    moveAdvisoryCustomerToWealth: (variables) =>
      client.mutate<
        Types.MoveAdvisoryCustomerToWealthMutation,
        Types.MoveAdvisoryCustomerToWealthMutationVariables
      >({
        mutation: Operations.MoveAdvisoryCustomerToWealthDocument,
        variables,
      }),
    moveToStatus: (variables) =>
      client.mutate<
        Types.MoveToStatusMutation,
        Types.MoveToStatusMutationVariables
      >({ mutation: Operations.MoveToStatusDocument, variables }),
    getCustomerActiveRiaDeal: (variables) =>
      client.query<
        Types.GetCustomerActiveRiaDealQuery,
        Types.GetCustomerActiveRiaDealQueryVariables
      >({ query: Operations.GetCustomerActiveRiaDealDocument, variables }),
    addCustomerToWaitlist: (variables) =>
      client.mutate<
        Types.AddCustomerToWaitlistMutation,
        Types.AddCustomerToWaitlistMutationVariables
      >({ mutation: Operations.AddCustomerToWaitlistDocument, variables }),
    getWaitlists: (variables) =>
      client.query<Types.GetWaitlistsQuery, Types.GetWaitlistsQueryVariables>({
        query: Operations.GetWaitlistsDocument,
        variables,
      }),
    searchCompanies: (variables) =>
      client.query<
        Types.SearchCompaniesQuery,
        Types.SearchCompaniesQueryVariables
      >({ query: Operations.SearchCompaniesDocument, variables }),
    selectNewCompany: (variables) =>
      client.mutate<
        Types.SelectNewCompanyMutation,
        Types.SelectNewCompanyMutationVariables
      >({ mutation: Operations.SelectNewCompanyDocument, variables }),
    getCompanyStatusAndSubstatus: (variables) =>
      client.query<
        Types.GetCompanyStatusAndSubstatusQuery,
        Types.GetCompanyStatusAndSubstatusQueryVariables
      >({ query: Operations.GetCompanyStatusAndSubstatusDocument, variables }),
    createScheduledAppointment: (variables) =>
      client.mutate<
        Types.CreateScheduledAppointmentMutation,
        Types.CreateScheduledAppointmentMutationVariables
      >({ mutation: Operations.CreateScheduledAppointmentDocument, variables }),
    getScheduledAppointments: (variables) =>
      client.query<
        Types.GetScheduledAppointmentsQuery,
        Types.GetScheduledAppointmentsQueryVariables
      >({ query: Operations.GetScheduledAppointmentsDocument, variables }),
    updateScheduledAppointment: (variables) =>
      client.mutate<
        Types.UpdateScheduledAppointmentMutation,
        Types.UpdateScheduledAppointmentMutationVariables
      >({ mutation: Operations.UpdateScheduledAppointmentDocument, variables }),
    createExerciseEvent: (variables) =>
      client.mutate<
        Types.CreateExerciseEventMutation,
        Types.CreateExerciseEventMutationVariables
      >({ mutation: Operations.CreateExerciseEventDocument, variables }),
    createRsuGrant: (variables) =>
      client.mutate<
        Types.CreateRsuGrantMutation,
        Types.CreateRsuGrantMutationVariables
      >({ mutation: Operations.CreateRsuGrantDocument, variables }),
    createEquityShareGrant: (variables) =>
      client.mutate<
        Types.CreateEquityShareGrantMutation,
        Types.CreateEquityShareGrantMutationVariables
      >({ mutation: Operations.CreateEquityShareGrantDocument, variables }),
    createStockOptionGrant: (variables) =>
      client.mutate<
        Types.CreateStockOptionGrantMutation,
        Types.CreateStockOptionGrantMutationVariables
      >({ mutation: Operations.CreateStockOptionGrantDocument, variables }),
    deleteExerciseEvent: (variables) =>
      client.mutate<
        Types.DeleteExerciseEventMutation,
        Types.DeleteExerciseEventMutationVariables
      >({ mutation: Operations.DeleteExerciseEventDocument, variables }),
    deleteRsuGrant: (variables) =>
      client.mutate<
        Types.DeleteRsuGrantMutation,
        Types.DeleteRsuGrantMutationVariables
      >({ mutation: Operations.DeleteRsuGrantDocument, variables }),
    deleteEquityShareGrant: (variables) =>
      client.mutate<
        Types.DeleteEquityShareGrantMutation,
        Types.DeleteEquityShareGrantMutationVariables
      >({ mutation: Operations.DeleteEquityShareGrantDocument, variables }),
    deleteStockOptionGrant: (variables) =>
      client.mutate<
        Types.DeleteStockOptionGrantMutation,
        Types.DeleteStockOptionGrantMutationVariables
      >({ mutation: Operations.DeleteStockOptionGrantDocument, variables }),
    updateRsuGrant: (variables) =>
      client.mutate<
        Types.UpdateRsuGrantMutation,
        Types.UpdateRsuGrantMutationVariables
      >({ mutation: Operations.UpdateRsuGrantDocument, variables }),
    updateEquityShareGrant: (variables) =>
      client.mutate<
        Types.UpdateEquityShareGrantMutation,
        Types.UpdateEquityShareGrantMutationVariables
      >({ mutation: Operations.UpdateEquityShareGrantDocument, variables }),
    updateStockOptionGrant: (variables) =>
      client.mutate<
        Types.UpdateStockOptionGrantMutation,
        Types.UpdateStockOptionGrantMutationVariables
      >({ mutation: Operations.UpdateStockOptionGrantDocument, variables }),
    updateExerciseEvent: (variables) =>
      client.mutate<
        Types.UpdateExerciseEventMutation,
        Types.UpdateExerciseEventMutationVariables
      >({ mutation: Operations.UpdateExerciseEventDocument, variables }),
    calculateVestedOptions: (variables) =>
      client.query<
        Types.CalculateVestedOptionsQuery,
        Types.CalculateVestedOptionsQueryVariables
      >({ query: Operations.CalculateVestedOptionsDocument, variables }),
    getAllGrants: (variables) =>
      client.query<Types.GetAllGrantsQuery, Types.GetAllGrantsQueryVariables>({
        query: Operations.GetAllGrantsDocument,
        variables,
      }),
    getCartaConnectionStatus: (variables) =>
      client.query<
        Types.GetCartaConnectionStatusQuery,
        Types.GetCartaConnectionStatusQueryVariables
      >({ query: Operations.GetCartaConnectionStatusDocument, variables }),
    getCartaLoginUrl: (variables) =>
      client.query<
        Types.GetCartaLoginUrlQuery,
        Types.GetCartaLoginUrlQueryVariables
      >({ query: Operations.GetCartaLoginUrlDocument, variables }),
    getEquityShareGrant: (variables) =>
      client.query<
        Types.GetEquityShareGrantQuery,
        Types.GetEquityShareGrantQueryVariables
      >({ query: Operations.GetEquityShareGrantDocument, variables }),
    getRsuGrant: (variables) =>
      client.query<Types.GetRsuGrantQuery, Types.GetRsuGrantQueryVariables>({
        query: Operations.GetRsuGrantDocument,
        variables,
      }),
    getRsuGrants: (variables) =>
      client.query<Types.GetRsuGrantsQuery, Types.GetRsuGrantsQueryVariables>({
        query: Operations.GetRsuGrantsDocument,
        variables,
      }),
    getShareGrants: (variables) =>
      client.query<
        Types.GetShareGrantsQuery,
        Types.GetShareGrantsQueryVariables
      >({ query: Operations.GetShareGrantsDocument, variables }),
    getStockOptionGrant: (variables) =>
      client.query<
        Types.GetStockOptionGrantQuery,
        Types.GetStockOptionGrantQueryVariables
      >({ query: Operations.GetStockOptionGrantDocument, variables }),
    getStockOptionGrants: (variables) =>
      client.query<
        Types.GetStockOptionGrantsQuery,
        Types.GetStockOptionGrantsQueryVariables
      >({ query: Operations.GetStockOptionGrantsDocument, variables }),
    importCartaData: (variables) =>
      client.mutate<
        Types.ImportCartaDataMutation,
        Types.ImportCartaDataMutationVariables
      >({ mutation: Operations.ImportCartaDataDocument, variables }),
    getCompanyNameByAffiliation: (variables) =>
      client.query<
        Types.GetCompanyNameByAffiliationQuery,
        Types.GetCompanyNameByAffiliationQueryVariables
      >({ query: Operations.GetCompanyNameByAffiliationDocument, variables }),
    createOrUpdateUsTaxInformation: (variables) =>
      client.mutate<
        Types.CreateOrUpdateUsTaxInformationMutation,
        Types.CreateOrUpdateUsTaxInformationMutationVariables
      >({
        mutation: Operations.CreateOrUpdateUsTaxInformationDocument,
        variables,
      }),
    getAllUsTaxInfo: (variables) =>
      client.query<
        Types.GetAllUsTaxInfoQuery,
        Types.GetAllUsTaxInfoQueryVariables
      >({ query: Operations.GetAllUsTaxInfoDocument, variables }),
    deleteAffiliation: (variables) =>
      client.mutate<
        Types.DeleteAffiliationMutation,
        Types.DeleteAffiliationMutationVariables
      >({ mutation: Operations.DeleteAffiliationDocument, variables }),
    validateEmploymentEndDate: (variables) =>
      client.query<
        Types.ValidateEmploymentEndDateQuery,
        Types.ValidateEmploymentEndDateQueryVariables
      >({ query: Operations.ValidateEmploymentEndDateDocument, variables }),
    createAffiliation: (variables) =>
      client.mutate<
        Types.CreateAffiliationMutation,
        Types.CreateAffiliationMutationVariables
      >({ mutation: Operations.CreateAffiliationDocument, variables }),
    disconnectCartaAccount: (variables) =>
      client.mutate<
        Types.DisconnectCartaAccountMutation,
        Types.DisconnectCartaAccountMutationVariables
      >({ mutation: Operations.DisconnectCartaAccountDocument, variables }),
    setCustomerFlag: (variables) =>
      client.mutate<
        Types.SetCustomerFlagMutation,
        Types.SetCustomerFlagMutationVariables
      >({ mutation: Operations.SetCustomerFlagDocument, variables }),
    signInWithSignInToken: (variables) =>
      client.mutate<
        Types.SignInWithSignInTokenMutation,
        Types.SignInWithSignInTokenMutationVariables
      >({ mutation: Operations.SignInWithSignInTokenDocument, variables }),
    updateAffiliationDetails: (variables) =>
      client.mutate<
        Types.UpdateAffiliationDetailsMutation,
        Types.UpdateAffiliationDetailsMutationVariables
      >({ mutation: Operations.UpdateAffiliationDetailsDocument, variables }),
    updateCustomer: (variables) =>
      client.mutate<
        Types.UpdateCustomerMutation,
        Types.UpdateCustomerMutationVariables
      >({ mutation: Operations.UpdateCustomerDocument, variables }),
    getAffiliationDetails: (variables) =>
      client.query<
        Types.GetAffiliationDetailsQuery,
        Types.GetAffiliationDetailsQueryVariables
      >({ query: Operations.GetAffiliationDetailsDocument, variables }),
    getAffiliationOverview: (variables) =>
      client.query<
        Types.GetAffiliationOverviewQuery,
        Types.GetAffiliationOverviewQueryVariables
      >({ query: Operations.GetAffiliationOverviewDocument, variables }),
    getAllAffiliationOverview: (variables) =>
      client.query<
        Types.GetAllAffiliationOverviewQuery,
        Types.GetAllAffiliationOverviewQueryVariables
      >({ query: Operations.GetAllAffiliationOverviewDocument, variables }),
    getCurrentPublicPrices: (variables) =>
      client.query<
        Types.GetCurrentPublicPricesQuery,
        Types.GetCurrentPublicPricesQueryVariables
      >({ query: Operations.GetCurrentPublicPricesDocument, variables }),
    getCustomerFlag: (variables) =>
      client.query<
        Types.GetCustomerFlagQuery,
        Types.GetCustomerFlagQueryVariables
      >({ query: Operations.GetCustomerFlagDocument, variables }),
    getCustomersByUser: (variables) =>
      client.query<
        Types.GetCustomersByUserQuery,
        Types.GetCustomersByUserQueryVariables
      >({ query: Operations.GetCustomersByUserDocument, variables }),
    getFormOptions: (variables) =>
      client.query<
        Types.GetFormOptionsQuery,
        Types.GetFormOptionsQueryVariables
      >({ query: Operations.GetFormOptionsDocument, variables }),
    getTotalNetWorth: (variables) =>
      client.query<
        Types.GetTotalNetWorthQuery,
        Types.GetTotalNetWorthQueryVariables
      >({ query: Operations.GetTotalNetWorthDocument, variables }),
    getUser: (variables) =>
      client.query<Types.GetUserQuery, Types.GetUserQueryVariables>({
        query: Operations.GetUserDocument,
        variables,
      }),
    fetchClientChecklist: (variables) =>
      client.query<
        Types.FetchClientChecklistQuery,
        Types.FetchClientChecklistQueryVariables
      >({ query: Operations.FetchClientChecklistDocument, variables }),
  };
  return sdk;
};
