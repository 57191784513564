export enum AuthProvider {
  Google = 'google',
  None = 'none',
}

const getOriginUrl = () => {
  if (typeof window === 'undefined') return '<origin>';
  return window.location.origin || '<origin>';
};

export const socialCallbackUrl = `${getOriginUrl()}/login/callback`;
