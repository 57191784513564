import { makeVar } from '@apollo/client';
import { UserFragment } from '@generated';
import { redirect } from '@remix-run/react';
import { EventEmitter } from 'events';
import JwtDecode from 'jwt-decode';
import { localStorageKeys } from 'src/constants/localStorage';
import { buildApiUrl } from 'src/environment/api';
import { AuthProvider, socialCallbackUrl } from 'src/environment/auth';
import { analytics } from 'src/services/analytics/analyticsService';
import { SignedUpVia } from 'src/types/analytics';
import { CognitoUser, TokensResponse } from 'src/types/cognito';
import { NotificationType } from 'src/types/notification';
import { query } from 'src/utils/query';
import { generateRandomId } from 'src/utils/strings';

import { createApolloSdk } from '../apiClients/createApolloSdk';
import { createGraphQlClient } from '../apiClients/createGraphQlClient';
import { createRestApiClient } from '../apiClients/createRestApiClient';
import { ApiEndpoints } from '../apiClients/endpoints';
import { customersMapReactiveVar } from '../apiClients/reactiveVars';
import { secfiStorage } from '../secfiStorage';
import { convertCognitoTokens } from './convertCognitoTokens';
import {
  AuthenticationService,
  AuthenticationServiceState,
  AuthenticationServiceTokens,
  InitError,
  SocialLoginCallback,
} from './types';

const oneSecondInMs = 1000;
const oneMinuteInMs = 60 * oneSecondInMs;
const socialLoginCallbackKey = 'socialLoginCallback';
const mirroringContextStorageKey = 'mirroringContext';

const fetchUserFromBackend = async (
  token: string,
  uuid: string
): Promise<UserFragment | null> => {
  const graphQlSdk = createApolloSdk(
    createGraphQlClient(() => token, null, true)
  );

  const userPromise = graphQlSdk.getUser({ uuid });
  const customersPromise = graphQlSdk.getCustomersByUser({ userUuid: uuid });

  const [userResult, customersResult] = await Promise.all([
    userPromise,
    customersPromise,
  ]);

  const user = userResult.data.user ?? null;
  const customers = customersResult.data.customers?.nodes ?? null;

  if (!user) {
    return null;
  }

  const result: UserFragment = {
    ...user,
    customer: customers?.[0] ?? null,
  };

  return result;
};

const signInWithToken = async (
  token: string
): Promise<AuthenticationServiceTokens | null> => {
  const graphQlSdk = createApolloSdk(createGraphQlClient(null, null, true));

  const response = await graphQlSdk.signInWithSignInToken({
    token,
  });

  if (response.errors) {
    return null;
  }

  const data = response.data?.signInWithSignInToken;

  switch (data?.__typename) {
    case 'SignInTokenInvalidError':
      throw new Error('Account creation token is invalid');
    case 'SignInResponse': {
      const tokens = convertCognitoTokens({
        access_token: data.access_token!,
        refresh_token: data.refresh_token!,
        token: data.token!,
        duration: data.duration!,
      });
      secfiStorage.setTokens(tokens);

      return tokens;
    }
    default:
      throw new Error('Unexpected response from the server');
  }
};

const getSecfiUserIdFromToken = (token: string): string | null => {
  const cognitoUser = JwtDecode<CognitoUser>(token);
  return typeof cognitoUser === 'object'
    ? cognitoUser?.['custom:secfi_uuid'] ?? cognitoUser?.sub
    : null;
};

function stripQueryParams(search: string, paramsToRemove: string[]): string {
  const searchParams = new URLSearchParams(search);

  paramsToRemove.forEach((param) => {
    searchParams.delete(param);
  });

  const newSearch = searchParams.toString();
  return newSearch ? `?${newSearch}` : '';
}

/**
 * Strips specified query parameters from the current URL and updates the browser history.
 * @param paramsToRemove An array of query parameter names to remove from the URL.
 */
function stripAndUpdateQueryParams(paramsToRemove: string[]): void {
  const currentSearch = window.location.search;
  const newSearch = stripQueryParams(currentSearch, paramsToRemove);

  // Only update if the search string has actually changed
  if (newSearch !== currentSearch) {
    const newUrl = [window.location.pathname, newSearch, window.location.hash]
      .filter(Boolean)
      .join('');

    window.history.replaceState({}, '', newUrl);
  }
}

const getSocialRedirectUrl = (
  provider: string,
  redirectUri: string,
  state: string
) => {
  const queryParams = {
    provider,
    redirect_uri: redirectUri,
    state,
  };

  return buildApiUrl(
    `${ApiEndpoints.SocialAuthorize}?${query.stringify(queryParams)}`
  );
};

export const createAuthenticationService = (): AuthenticationService => {
  /**
   * This "store" object is central to the authentication service. It holds all
   * the data necessary for the platform to function. In the new implementation,
   * this store exists outside of a component tree and is easily accessible by
   * non-React code.
   */
  const store = makeVar<AuthenticationServiceState>({
    isReady: false,
    tokens: null,
    user: null,
    impersonatee: null,
    initError: null,
  });

  const bus = new EventEmitter();

  let initTask: Promise<UserFragment | null> | null = null;
  let refreshTokensTask: Promise<
    Omit<TokensResponse, 'refresh_token'>
  > | null = null;

  const init = async () => {
    const state = store();

    if (state.isReady) {
      return state.user;
    }

    if (initTask) {
      return await initTask;
    }

    initTask = (async () => {
      let tokens: AuthenticationServiceTokens | null = null;
      let user: UserFragment | null = null;
      let impersonatee: UserFragment | null = null;
      let isNetworkError = false;
      let initError: InitError | null = null;

      const urlSearchParams = new URLSearchParams(window.location.search);

      const signInToken = urlSearchParams.get('signInToken');
      const signInEmail = urlSearchParams.get('signInEmail');

      // - save the id for the potential impersonation (mirroring)  request
      const impersonateeId =
        urlSearchParams.get('user') ??
        secfiStorage.getItem(mirroringContextStorageKey);

      if (impersonateeId) {
        secfiStorage.setItem(mirroringContextStorageKey, impersonateeId);
      }

      // - handle possible social login redirect (state, code => exchange for token)

      if (window.location.pathname.startsWith('/login/callback')) {
        const code = urlSearchParams.get('code');
        const state = urlSearchParams.get('state');
        const existingSocialLoginCallback = secfiStorage.getItem<SocialLoginCallback>(
          socialLoginCallbackKey
        );

        if (
          code &&
          state &&
          existingSocialLoginCallback &&
          state === existingSocialLoginCallback.state
        ) {
          const referralCode = secfiStorage.getItem(
            localStorageKeys.referralCode
          );
          tokens = await createRestApiClient(null, null)
            .exchangeCodeForTokens(
              code,
              socialCallbackUrl,
              existingSocialLoginCallback.trackingData,
              referralCode
            )
            .then((response) => convertCognitoTokens(response))
            .catch(() => null);
          if (tokens) {
            secfiStorage.setTokens(tokens);
            if (existingSocialLoginCallback.location) {
              const { pathname, search, hash } =
                existingSocialLoginCallback?.location ?? {};
              const uri = [pathname, search, hash].filter(Boolean).join('');
              window.history.replaceState({}, '', uri);
            }
          }
        }
      }

      // - if this is not social login, attempt to retrieve existing tokens from LS
      if (tokens === null) {
        tokens = secfiStorage.getTokens();
      }

      // - if the tokens are not present, attempt to resolve them from the signInToken
      if (signInToken) {
        if (tokens === null) {
          tokens = await signInWithToken(signInToken).catch((e) => {
            initError = {
              message: e.message,
              intent: NotificationType.Error,
            };
            return null;
          });
        } else {
          // if the tokens are already present, lets remove the signInToken from the URL
          stripAndUpdateQueryParams(['signInToken']);
        }
      }
      if (!tokens && signInEmail) {
        initError = {
          message: `Account ${signInEmail} already exists. Please sign in.`,
          intent: NotificationType.Info,
        };
      }

      // - resolve the user from the tokens
      try {
        if (tokens && tokens.expiresAt < Date.now()) {
          // if the tokens exist, but the id / access token has expired,
          // attempt to obtain the new tokens using the refresh token
          tokens = convertCognitoTokens(
            await createRestApiClient(null, null).refreshTokens(
              tokens.refreshToken
            ),
            tokens.refreshToken
          );

          // persist new tokens
          secfiStorage.setTokens(tokens);
        }

        // if the tokens are still valid, attempt to resolve the user
        if (tokens && tokens.expiresAt > Date.now()) {
          const userSecfiUuid = getSecfiUserIdFromToken(tokens.token);

          if (userSecfiUuid) {
            const candidateUser = await fetchUserFromBackend(
              tokens.token,
              userSecfiUuid
            );
            user = candidateUser;
            const candidateUserCustomer = candidateUser?.customer ?? null;
            if (candidateUser && candidateUserCustomer) {
              customersMapReactiveVar({
                ...customersMapReactiveVar(),
                [candidateUser.uuid]: candidateUserCustomer,
              });
            }
            if (candidateUser && impersonateeId) {
              const candidateImpersonateeUser = await fetchUserFromBackend(
                tokens.token,
                impersonateeId
              );
              impersonatee = candidateImpersonateeUser;
              const candidateImpersonateeCustomer =
                candidateImpersonateeUser?.customer ?? null;
              if (candidateImpersonateeUser && candidateImpersonateeCustomer) {
                customersMapReactiveVar({
                  ...customersMapReactiveVar(),
                  [candidateImpersonateeUser.uuid]: candidateImpersonateeCustomer,
                });
              }
            }
          }
        }
      } catch (e) {
        if (e instanceof Error) {
          const message = e.message.toLowerCase();
          isNetworkError = [
            'failed to fetch',
            'network error',
            'load failed',
            'cancelled',
          ].includes(message);
        }
      }

      // clear persisted tokens if no user has been resolved from them,
      // if the error is not a network error
      if (!user && !isNetworkError) {
        tokens = null;
        secfiStorage.clearTokens();
      }

      if (signInToken || signInEmail) {
        stripAndUpdateQueryParams(['signInToken', 'signInEmail']);
      }

      store({
        isReady: true,
        tokens,
        user,
        impersonatee,
        initError,
      });

      return user;
    })().then((returnValue) => {
      initTask = null;
      return returnValue;
    });

    return await initTask;
  };

  const login = async (
    username: string,
    password: string
  ): Promise<boolean> => {
    const state = store();

    if (initTask) {
      await initTask;
    }

    if (state.tokens) {
      return true;
    }

    let user: UserFragment | null = null;
    let tokensObject: AuthenticationServiceTokens | null = null;

    const tokens = await createRestApiClient(
      null,
      null
    ).signinWithUsernameAndPassword(username, password);
    tokensObject = convertCognitoTokens(tokens);
    const secfiUserId = getSecfiUserIdFromToken(tokens.token);
    if (secfiUserId) {
      const candidateUser = await fetchUserFromBackend(
        tokensObject.token,
        secfiUserId
      );
      user = candidateUser;
    }

    if (!user) {
      tokensObject = null;
    }

    if (tokensObject) {
      secfiStorage.setTokens(tokensObject);
    }

    store({
      isReady: true,
      tokens: tokensObject,
      user,
      impersonatee: null,
      initError: null,
    });

    return user !== null;
  };

  const ensureTokensLiveness = async () => {
    if (refreshTokensTask) {
      return refreshTokensTask.then(() => undefined);
    }

    if (initTask) {
      await initTask;
    }

    const state = store();

    if (!state.tokens) {
      return;
    }

    const threshold = Date.now() + oneMinuteInMs;

    if (state.tokens.expiresAt > threshold) {
      return;
    }

    const refreshToken = state.tokens.refreshToken;
    refreshTokensTask = createRestApiClient(null, null).refreshTokens(
      refreshToken
    );

    try {
      const tokens = await refreshTokensTask;
      const tokensObject = convertCognitoTokens(tokens, refreshToken);
      secfiStorage.setTokens(tokensObject);
      store({
        ...store(),
        tokens: tokensObject,
      });
      return;
    } finally {
      refreshTokensTask = null;
    }
  };

  const logout = () => {
    store({
      isReady: true,
      tokens: null,
      user: null,
      impersonatee: null,
      initError: null,
    });
    secfiStorage.clearTokens();
    secfiStorage.clear();
    sessionStorage.clear();
    analytics.reset();
    bus.emit('logout');
  };

  const loginSocial = () => {
    const socialLoginState = generateRandomId();

    const targetLocation = {
      pathname: location.pathname,
      hash: location.hash,
      search: stripQueryParams(location.search, ['code', 'state', 'carta']),
    };

    secfiStorage.setItem(socialLoginCallbackKey, {
      location: targetLocation,
      provider: AuthProvider.Google,
      state: socialLoginState,
      trackingData: {
        provider: AuthProvider.Google,
        signed_up_via: SignedUpVia.SignUp,
        signed_up_with: 'name_and_email',
      },
    } as SocialLoginCallback);

    const uri = getSocialRedirectUrl(
      AuthProvider.Google,
      socialCallbackUrl,
      socialLoginState
    );

    window.location.assign(uri);
  };

  const getToken = (): string | null => {
    const state = store();
    return state.tokens?.token ?? null;
  };

  const loginWithTokens = async (tokens: AuthenticationServiceTokens) => {
    let user: UserFragment | null = null;

    const userSecfiUuid = getSecfiUserIdFromToken(tokens.token);

    if (userSecfiUuid) {
      const candidateUser = await fetchUserFromBackend(
        tokens.token,
        userSecfiUuid
      );
      user = candidateUser;
      const candidateUserCustomer = candidateUser?.customer ?? null;
      if (candidateUser && candidateUserCustomer) {
        customersMapReactiveVar({
          ...customersMapReactiveVar(),
          [candidateUser.uuid]: candidateUserCustomer,
        });
      }
    }

    if (user) {
      secfiStorage.setTokens(tokens);
      store({
        isReady: true,
        tokens,
        user,
        impersonatee: null,
        initError: null,
      });
      return;
    }

    throw new Error("couldn't resolve tokens to a user");
  };

  const addLogoutHandler = (handler: () => void): (() => void) => {
    const logoutSignalName = 'logout';
    bus.addListener(logoutSignalName, handler);
    return () => {
      bus.removeListener(logoutSignalName, handler);
    };
  };

  const remix_ensureAuthenticated = async () => {
    let user: UserFragment | null = null;

    const state = store();
    if (state.isReady) {
      user = state.impersonatee ?? state.user;
    } else {
      await init();
      // note that call to `init` might have updated the store,
      // so we need to re-read the user from the store
      const currentState = store();
      user = currentState.impersonatee ?? currentState.user;
    }

    if (!user) {
      throw redirect('/login');
    }

    return user;
  };

  return {
    store,
    init,
    login,
    logout,
    addLogoutHandler,
    loginSocial,
    loginWithTokens,
    getToken,
    ensureTokensLiveness,
    remix_ensureAuthenticated,
  };
};
