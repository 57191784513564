import { ProposalFile, User } from '@generated';
import axios from 'axios';
import pick from 'lodash/pick';
import { buildApiUrl } from 'src/environment/api';
import { createAxiosSentryErrorInterceptor } from 'src/services/networkErrors';
import { LoginTrackingData, SignUpTrackingData } from 'src/types/analytics';
import { TokensResponse } from 'src/types/cognito';
import { getDefaultHeaders } from 'src/utils/headers';
import { query } from 'src/utils/query';

import { ApiEndpoints } from './endpoints';
import { RestApiClient } from './types';

export const getLogoutRedirectUrl = () => {
  const params = {
    redirect_uri: `${window.location.origin}/login`,
  };

  return buildApiUrl(`${ApiEndpoints.Logout}?${query.stringify(params)}`);
};

/**
 * TODO: the client must be generated from Swagger files
 */
export const createRestApiClient = (
  getToken: (() => string | null) | null,
  ensureTokensLiveness: (() => Promise<void>) | null
): RestApiClient => {
  const client = axios.create();

  if (import.meta.env.NODE_ENV !== 'test') {
    client.interceptors.response.use(
      (response) => response,
      createAxiosSentryErrorInterceptor('axios-rest')
    );
  }

  client.interceptors.request.use((config) => {
    if (config.data instanceof FormData) {
      Object.assign(config.headers, {
        'Content-Type': undefined,
      });
    } else {
      Object.assign(config.headers, getDefaultHeaders());
    }
    return config;
  });

  if (getToken !== null) {
    client.interceptors.request.use(async (config) => {
      const ensureTokensLivenessTask =
        ensureTokensLiveness?.() ?? Promise.resolve();

      await ensureTokensLivenessTask;

      const token = getToken();
      if (!config.headers['Authorization'] && token) {
        Object.assign(config.headers, { Authorization: `Bearer ${token}` });
      }
      return config;
    });
  }

  const requestResetPassword = (email: string, resetFlow?: string | null) => {
    return client
      .post(buildApiUrl(ApiEndpoints.RequestResetPassword), {
        email,
        reset_flow: resetFlow,
      })
      .then(() => undefined);
  };

  const resetPassword = (username: string, password: string, otp: string) => {
    return client
      .post(buildApiUrl(ApiEndpoints.DoResetPassword), {
        username,
        password,
        confirmation_code: otp,
      })
      .then(() => undefined);
  };

  const signinWithUsernameAndPassword = (
    username: string,
    password: string,
    provider?: string
  ) => {
    return client
      .post<TokensResponse>(buildApiUrl(ApiEndpoints.Login), {
        username,
        password,
        provider,
      })
      .then(({ data }) =>
        pick(data, 'access_token', 'refresh_token', 'token', 'duration')
      );
  };

  const refreshTokens = (refreshToken: string) => {
    return client
      .post<Omit<TokensResponse, 'refresh_token'>>(
        buildApiUrl(ApiEndpoints.RefreshToken),
        {
          token: refreshToken,
        },
        { withCredentials: true }
      )
      .then(({ data }) => data);
  };

  const changePassword = (currentPassword: string, newPassword: string) => {
    return client
      .post(buildApiUrl(ApiEndpoints.DoChangePassword), {
        current_password: currentPassword,
        new_password: newPassword,
      })
      .then(() => undefined);
  };

  const exchangeCodeForTokens = (
    code: string,
    redirectUri: string,
    trackingData?: Partial<LoginTrackingData> | Partial<SignUpTrackingData>,
    referralCode?: string | null
  ) => {
    return client
      .get<TokensResponse>(buildApiUrl(ApiEndpoints.GetSocialToken), {
        params: {
          code,
          redirect_uri: redirectUri,
          referral_code: referralCode,
          ...trackingData,
        },
      })
      .then(({ data }) =>
        pick(data, 'access_token', 'refresh_token', 'token', 'duration')
      );
  };

  const verifyEmail = (token: string): Promise<string> => {
    return client
      .post<User>(buildApiUrl(ApiEndpoints.VerifyEmailToken), { token })
      .then((response) => response.data.username);
  };

  const getProposalFiles = (proposalUuid: string): Promise<ProposalFile[]> => {
    return client
      .get<ProposalFile[]>(buildApiUrl(ApiEndpoints.ProposalFiles), {
        params: {
          proposal: proposalUuid,
        },
      })
      .then((response) => response.data);
  };

  const downloadProposalFile = (url: string): Promise<Blob> => {
    return client
      .get(url, { responseType: 'blob' })
      .then((response) => response.data);
  };

  return {
    signinWithUsernameAndPassword,
    requestResetPassword,
    resetPassword,
    changePassword,
    refreshTokens,
    exchangeCodeForTokens,
    verifyEmail,
    getProposalFiles,
    downloadProposalFile,
    axiosInstance: client, // TODO:SEC-1006: temporary escape hatch
  };
};
