/* tslint:disable */
/* eslint-disable */
/* istanbul ignore file */

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    AllocationTableBase: ['AllocationTable'],
    EPTimelineItemData: [
      'EPCompanyAndTaxDetailsItemData',
      'EPCompanyPublicListingItemData',
      'EPExerciseItemData',
      'EPExitProjectionItemData',
      'EPGainsItemData',
      'EPLiquidityFromSecondaryMarketItemData',
      'EPLiquidityFromTenderOfferItemData',
      'EPOptionGrantItemData',
      'EPSecondaryMarketItemData',
      'EPShareGrantItemData',
      'EPTenderOfferItemData',
    ],
    FinancingFormModel: [
      'Contract',
      'PrivateSecuritiesContract',
      'SpringingLienAssignmentAgreement',
      'SpringingLienPSC',
      'SpringingLienStockPledge',
    ],
    GraphMarker: [
      'GraphMarkerCliff',
      'GraphMarkerEndOfEmployment',
      'GraphMarkerEndOfVesting',
      'GraphMarkerFirstHolding',
      'GraphMarkerFundingRound',
      'GraphMarkerIPO',
      'GraphMarkerStockSplit',
    ],
    IBankAccountInformation: [
      'BankAccountInformation',
      'CustomerBankAccountInformation',
    ],
    PortfolioErrorDetails: ['GenericErrorDetails'],
    PortfolioView: ['AssetTypeOverview', 'TotalNetWorthPortfolioView'],
    ScheduleAndSignUpResponse: ['AccountAlreadyExistsError', 'SignInToken'],
    SignInWithSignInTokenResponse: [
      'SignInResponse',
      'SignInTokenInvalidError',
    ],
  },
};
export default result;
