/* tslint:disable */

/* eslint-disable */

/* istanbul ignore file */
import type { TypePolicy } from '@apollo/client';

export const baseUuidTypePolicy: TypePolicy = { keyFields: ['uuid'] };

/* A dictionary of all existing (output) GraphQL types. */
export type TypePolicies = {
  AccountAlreadyExistsError: TypePolicy;
  AdjustedFundingRound: TypePolicy;
  Advisor: TypePolicy;
  AdvisorAssignmentRule: TypePolicy;
  Affiliation: TypePolicy;
  AllAdvisorsResponse: TypePolicy;
  AllAffiliationsResponse: TypePolicy;
  AllCompanyAssessmentsResponse: TypePolicy;
  AllCompanyForecastsResponse: TypePolicy;
  AllCustomersResponse: TypePolicy;
  AllFinancingObjects: TypePolicy;
  AllFundsResponse: TypePolicy;
  AllGrants: TypePolicy;
  AllGrantsValidation: TypePolicy;
  AllInvestorsResponse: TypePolicy;
  AllProposalRequests: TypePolicy;
  AllProposalsResponse: TypePolicy;
  AllUsTaxInformationResponse: TypePolicy;
  AllocationTable: TypePolicy;
  AllocationTableEntry: TypePolicy;
  AmtCrossOverScenario: TypePolicy;
  AmtCrossoverBreakDown: TypePolicy;
  AmtSummaryAtExcercise: TypePolicy;
  AsanaTask: TypePolicy;
  AssetTypeOverview: TypePolicy;
  BankAccountInformation: TypePolicy;
  BasicFormOption: TypePolicy;
  Bracket: TypePolicy;
  CapTable: TypePolicy;
  CartaAccountDisconnectResult: TypePolicy;
  CartaConnectionStatusResult: TypePolicy;
  CartaDataImportResult: TypePolicy;
  CartaLoginUrlResponse: TypePolicy;
  CartaPasswordlessSignupResponse: TypePolicy;
  ClientChecklist: TypePolicy;
  ClientChecklistItem: TypePolicy;
  ClientChecklistItemList: TypePolicy;
  ClientChecklistList: TypePolicy;
  Company: TypePolicy;
  CompanyAssessment: TypePolicy;
  CompanyBankAccountInformation: TypePolicy;
  CompanyExitProjections: TypePolicy;
  CompanyForecast: TypePolicy;
  CompanyListResponse: TypePolicy;
  CompanyRow: TypePolicy;
  CompanySearchResultItem: TypePolicy;
  CompanyShortInfo: TypePolicy;
  CompanyTriageListResponse: TypePolicy;
  CompanyTriageRow: TypePolicy;
  CompanyTriageUserRow: TypePolicy;
  CompanyTriageUsersListResponse: TypePolicy;
  ComplianceInformationDataRIA: TypePolicy;
  ComplianceInformationFinancing: TypePolicy;
  ComplianceInformationRIA: TypePolicy;
  ConfigProgress: TypePolicy;
  ConfigProgressBreakdown: TypePolicy;
  Contract: TypePolicy;
  ContractFile: TypePolicy;
  ContractVariant: TypePolicy;
  Currency: TypePolicy;
  CurrencyModel: TypePolicy;
  Customer: TypePolicy;
  CustomerBankAccountInformation: TypePolicy;
  CustomerFlag: TypePolicy;
  CustomerWaitlist: TypePolicy;
  CustomerWaitlistQuestion: TypePolicy;
  DealCalculations: TypePolicy;
  EPCompanyAndTaxDetailsItemData: TypePolicy;
  EPCompanyDetailsItemData: TypePolicy;
  EPCompanyPublicListingItemData: TypePolicy;
  EPExerciseCostsEstimation: TypePolicy;
  EPExerciseItemData: TypePolicy;
  EPExerciseLaterProjectionData: TypePolicy;
  EPExerciseNowEarlyData: TypePolicy;
  EPExerciseNowEarlyGrantData: TypePolicy;
  EPExitDate: TypePolicy;
  EPExitDateChoices: TypePolicy;
  EPExitMultiplier: TypePolicy;
  EPExitMultiplierChoices: TypePolicy;
  EPExitProjectionItemData: TypePolicy;
  EPExitValueEstimation: TypePolicy;
  EPGainsItemData: TypePolicy;
  EPGrantExerciseCostsEstimation: TypePolicy;
  EPGrantSold: TypePolicy;
  EPLiquidityFromSecondaryMarketItemData: TypePolicy;
  EPLiquidityFromTenderOfferItemData: TypePolicy;
  EPOptionGrantItemData: TypePolicy;
  EPOptionGrantsExitValueEstimation: TypePolicy;
  EPPlan: TypePolicy;
  EPPlanOptionGrant: TypePolicy;
  EPPlanShareGrant: TypePolicy;
  EPSecondaryMarketData: TypePolicy;
  EPSecondaryMarketGrantData: TypePolicy;
  EPSecondaryMarketItemData: TypePolicy;
  EPShareGrantItemData: TypePolicy;
  EPShareGrantsExitValueEstimation: TypePolicy;
  EPTenderOfferData: TypePolicy;
  EPTenderOfferGrantData: TypePolicy;
  EPTenderOfferItemData: TypePolicy;
  EPTimeline: TypePolicy;
  EPTimelineItem: TypePolicy;
  EPTimelineWrapper: TypePolicy;
  EPVestedGrantQuantity: TypePolicy;
  EffectiveTaxRates: TypePolicy;
  EquityCurrency: TypePolicy;
  EquityGraph: TypePolicy;
  EquityMutations: TypePolicy;
  EquityQueries: TypePolicy;
  EquitySeries: TypePolicy;
  EquityShareGrant: TypePolicy;
  EquityShareGrantSummary: TypePolicy;
  EquityVerification: TypePolicy;
  EquityVerificationFile: TypePolicy;
  ExcerciseOnExitScenario: TypePolicy;
  ExercisableQuantity: TypePolicy;
  ExerciseDetailParentStockOptionGrant: TypePolicy;
  ExerciseEvent: TypePolicy;
  ExerciseEventSummary: TypePolicy;
  ExitComparison: TypePolicy;
  ExitProjection: TypePolicy;
  ExitScenario: TypePolicy;
  ExitScenarioDetail: TypePolicy;
  ExitScenarioDetails: TypePolicy;
  Feedback: TypePolicy;
  File: TypePolicy;
  FinancingCategoryStatus: TypePolicy;
  FinancingFormStatus: TypePolicy;
  FinancingObject: TypePolicy;
  FinancingObjectLoi: TypePolicy;
  FinancingOptionGrant: TypePolicy;
  FinancingShareGrant: TypePolicy;
  FinancingStatusCount: TypePolicy;
  FinancingStatusPair: TypePolicy;
  FinancingStatusSubstatuses: TypePolicy;
  FixedTermScenario: TypePolicy;
  FormOptions: TypePolicy;
  Fund: TypePolicy;
  GenericErrorDetails: TypePolicy;
  GraphMarkerCliff: TypePolicy;
  GraphMarkerEndOfEmployment: TypePolicy;
  GraphMarkerEndOfVesting: TypePolicy;
  GraphMarkerFirstHolding: TypePolicy;
  GraphMarkerFundingRound: TypePolicy;
  GraphMarkerIPO: TypePolicy;
  GraphMarkerStockSplit: TypePolicy;
  IdentificationFile: TypePolicy;
  IdentificationInformation: TypePolicy;
  IdentificationInformationRIA: TypePolicy;
  Industry: TypePolicy;
  IndustryTree: TypePolicy;
  Institution: TypePolicy;
  IntermediaryServicesAgreement: TypePolicy;
  Investor: TypePolicy;
  JobPosition: TypePolicy;
  KYCInquiry: TypePolicy;
  KYCInquiryCreationType: TypePolicy;
  KYCInquiryEndType: TypePolicy;
  ListViewTotalCounts: TypePolicy;
  MockExitScenario: TypePolicy;
  MockProposal: TypePolicy;
  Mutation: TypePolicy;
  MutationsRIA: TypePolicy;
  NetRateComponents: TypePolicy;
  OnboardingListResponse: TypePolicy;
  OnboardingRow: TypePolicy;
  PayStubAccountStatement: TypePolicy;
  Period: TypePolicy;
  PersonalInformationDataRIA: TypePolicy;
  PersonalInformationFinancing: TypePolicy;
  PersonalInformationRIA: TypePolicy;
  PortfolioError: TypePolicy;
  PortfolioGraph: TypePolicy;
  PrivateSecuritiesContract: TypePolicy;
  PrivateSecuritiesContractFile: TypePolicy;
  Proposal: TypePolicy;
  ProposalFile: TypePolicy;
  ProposalRegenerationResponse: TypePolicy;
  ProposalRequest: TypePolicy;
  ProposalRequestPersonalSnapshot: TypePolicy;
  ProposalRow: TypePolicy;
  ProposalTaxDetails: TypePolicy;
  ProposalsListResponse: TypePolicy;
  PublicPrice: TypePolicy;
  QueriesRIA: TypePolicy;
  Query: TypePolicy;
  RIADeal: TypePolicy;
  RIADealList: TypePolicy;
  RIADealListResponse: TypePolicy;
  RIADealRow: TypePolicy;
  RIAOnboardingFile: TypePolicy;
  RSUGrant: TypePolicy;
  RSUGrantSummary: TypePolicy;
  RampSchedule: TypePolicy;
  RampScheduleOverview: TypePolicy;
  RampupTermScenarios: TypePolicy;
  RequestRow: TypePolicy;
  RequestsListResponse: TypePolicy;
  RiskAssessment: TypePolicy;
  RiskAssessmentAnswer: TypePolicy;
  RiskAssessmentQuestionType: TypePolicy;
  SaleEvent: TypePolicy;
  Scenario: TypePolicy;
  ScheduledAppointment: TypePolicy;
  SecfiFinancingScenario: TypePolicy;
  SecondariesRequest: TypePolicy;
  SelectedOptionGrant: TypePolicy;
  SelectedShareGrant: TypePolicy;
  ShareGrantExerciseDetail: TypePolicy;
  SignContract: TypePolicy;
  SignInResponse: TypePolicy;
  SignInToken: TypePolicy;
  SignInTokenInvalidError: TypePolicy;
  SignUpResponse: TypePolicy;
  SpringingLienAssignmentAgreement: TypePolicy;
  SpringingLienAssignmentAgreementFile: TypePolicy;
  SpringingLienPSC: TypePolicy;
  SpringingLienPSCFile: TypePolicy;
  SpringingLienStockPledge: TypePolicy;
  SpringingLienStockPledgeFile: TypePolicy;
  StockOptionGrant: TypePolicy;
  StockOptionGrantSummary: TypePolicy;
  StockSplit: TypePolicy;
  Subdivision: TypePolicy;
  TaxBrackets: TypePolicy;
  TaxBreakdown: TypePolicy;
  TaxDetails: TypePolicy;
  TaxDetailsConstants: TypePolicy;
  TaxWithholdings: TypePolicy;
  TaxWithholdingsFile: TypePolicy;
  TaxWithholdingsOptionRelation: TypePolicy;
  Terms: TypePolicy;
  TermsRate: TypePolicy;
  TimelinePoint: TypePolicy;
  TotalNetWorthPortfolioView: TypePolicy;
  TrustDetails: TypePolicy;
  UsScenarios: TypePolicy;
  UsTaxInformation: TypePolicy;
  User: TypePolicy;
  UserIdentification: TypePolicy;
  UserRow: TypePolicy;
  UsersListResponse: TypePolicy;
  Vertical: TypePolicy;
  VestedOptionsCalculation: TypePolicy;
  VestingScheduleEvent: TypePolicy;
  VestingScheduleTemplate: TypePolicy;
  W9Form: TypePolicy;
  clientChecklistQueries: TypePolicy;
  riaDealMutations: TypePolicy;
  riaDealQueries: TypePolicy;
};

/* Base type policies config (using 'uuid' as cache key). */
export const baseTypePolicies: Partial<TypePolicies> = {
  Advisor: baseUuidTypePolicy,
  Affiliation: baseUuidTypePolicy,
  AsanaTask: baseUuidTypePolicy,
  BankAccountInformation: baseUuidTypePolicy,
  CapTable: baseUuidTypePolicy,
  CartaPasswordlessSignupResponse: baseUuidTypePolicy,
  ClientChecklist: baseUuidTypePolicy,
  ClientChecklistItem: baseUuidTypePolicy,
  Company: baseUuidTypePolicy,
  CompanyAssessment: baseUuidTypePolicy,
  CompanyBankAccountInformation: baseUuidTypePolicy,
  CompanyForecast: baseUuidTypePolicy,
  CompanySearchResultItem: baseUuidTypePolicy,
  CompanyShortInfo: baseUuidTypePolicy,
  ComplianceInformationFinancing: baseUuidTypePolicy,
  ComplianceInformationRIA: baseUuidTypePolicy,
  Contract: baseUuidTypePolicy,
  ContractFile: baseUuidTypePolicy,
  Customer: baseUuidTypePolicy,
  CustomerBankAccountInformation: baseUuidTypePolicy,
  CustomerWaitlist: baseUuidTypePolicy,
  CustomerWaitlistQuestion: baseUuidTypePolicy,
  DealCalculations: baseUuidTypePolicy,
  EPExerciseNowEarlyGrantData: baseUuidTypePolicy,
  EPGrantExerciseCostsEstimation: baseUuidTypePolicy,
  EPOptionGrantsExitValueEstimation: baseUuidTypePolicy,
  EPSecondaryMarketGrantData: baseUuidTypePolicy,
  EPShareGrantsExitValueEstimation: baseUuidTypePolicy,
  EPTenderOfferGrantData: baseUuidTypePolicy,
  EPVestedGrantQuantity: baseUuidTypePolicy,
  EquitySeries: baseUuidTypePolicy,
  EquityShareGrant: baseUuidTypePolicy,
  EquityVerification: baseUuidTypePolicy,
  EquityVerificationFile: baseUuidTypePolicy,
  ExerciseDetailParentStockOptionGrant: baseUuidTypePolicy,
  ExerciseEvent: baseUuidTypePolicy,
  FinancingFormStatus: baseUuidTypePolicy,
  FinancingObject: baseUuidTypePolicy,
  FinancingOptionGrant: baseUuidTypePolicy,
  FinancingShareGrant: baseUuidTypePolicy,
  Fund: baseUuidTypePolicy,
  IdentificationFile: baseUuidTypePolicy,
  IdentificationInformation: baseUuidTypePolicy,
  IdentificationInformationRIA: baseUuidTypePolicy,
  IntermediaryServicesAgreement: baseUuidTypePolicy,
  Investor: baseUuidTypePolicy,
  JobPosition: baseUuidTypePolicy,
  PayStubAccountStatement: baseUuidTypePolicy,
  PersonalInformationFinancing: baseUuidTypePolicy,
  PersonalInformationRIA: baseUuidTypePolicy,
  PrivateSecuritiesContract: baseUuidTypePolicy,
  PrivateSecuritiesContractFile: baseUuidTypePolicy,
  Proposal: baseUuidTypePolicy,
  ProposalFile: baseUuidTypePolicy,
  ProposalRegenerationResponse: baseUuidTypePolicy,
  ProposalRequest: baseUuidTypePolicy,
  RIADeal: baseUuidTypePolicy,
  RIADealRow: baseUuidTypePolicy,
  RIAOnboardingFile: baseUuidTypePolicy,
  RSUGrant: baseUuidTypePolicy,
  RiskAssessment: baseUuidTypePolicy,
  RiskAssessmentQuestionType: baseUuidTypePolicy,
  SaleEvent: baseUuidTypePolicy,
  ScheduledAppointment: baseUuidTypePolicy,
  SecondariesRequest: baseUuidTypePolicy,
  SelectedOptionGrant: baseUuidTypePolicy,
  SelectedShareGrant: baseUuidTypePolicy,
  ShareGrantExerciseDetail: baseUuidTypePolicy,
  SignInResponse: baseUuidTypePolicy,
  SignUpResponse: baseUuidTypePolicy,
  SpringingLienAssignmentAgreement: baseUuidTypePolicy,
  SpringingLienAssignmentAgreementFile: baseUuidTypePolicy,
  SpringingLienPSC: baseUuidTypePolicy,
  SpringingLienPSCFile: baseUuidTypePolicy,
  SpringingLienStockPledge: baseUuidTypePolicy,
  SpringingLienStockPledgeFile: baseUuidTypePolicy,
  StockOptionGrant: baseUuidTypePolicy,
  StockSplit: baseUuidTypePolicy,
  TaxWithholdings: baseUuidTypePolicy,
  TaxWithholdingsFile: baseUuidTypePolicy,
  TaxWithholdingsOptionRelation: baseUuidTypePolicy,
  Terms: baseUuidTypePolicy,
  UsTaxInformation: baseUuidTypePolicy,
  User: baseUuidTypePolicy,
  W9Form: baseUuidTypePolicy,
};
