import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  fromPromise,
} from '@apollo/client';
import { InMemoryCache, NormalizedCacheObject } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { fragmentMatcher } from '@generated';
import { buildFacadeUrl, customUrlKey } from 'src/environment/api';
import { has } from 'src/utils/has';
import { getDefaultHeaders } from 'src/utils/headers';

import {
  captureNetworkError,
  getExtrasForGraphQlError,
  getExtrasForGraphQlOperation,
} from '../networkErrors';
import { typePolicies } from './typePolicies';

export const createGraphQlClient = (
  getToken: (() => string | null) | null,
  ensureTokensLiveness: (() => Promise<void>) | null,
  /**
   * If `true`, the client will not connect to the Apollo DevTools.
   *
   * Since we are creating new Apollo clients in multiple places, we only
   * need to connect the primary (persisted) one.
   */
  isAdHocClient = false
): ApolloClient<NormalizedCacheObject> => {
  const httpLink = createHttpLink({
    fetch,
    uri: ({ operationName }) => {
      return buildFacadeUrl(operationName);
    },
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (networkError) {
      let statusCode: number | null = null;
      let url: string | null = null;

      if (has(networkError, 'statusCode')) {
        statusCode = networkError.statusCode;
        url = networkError.response.url;
      }

      captureNetworkError(
        networkError,
        `[GQL] ${operation.operationName}`,
        [operation.operationName, networkError.message],
        'graphql-transport',
        {
          ...getExtrasForGraphQlOperation(operation),
          url: url ?? buildFacadeUrl(operation.operationName),
          // if statusCode is null, it's most likely a CORS error.
          // CORS error could be due to improperly configured CORS (duh!)
          // or due to an invalid API URL.
          statusCode,

          // `errorCode` must also be here. unfortunately, when `fetch`
          // is used as HTTP client, it's not possible to extract error code
        }
      );
    }

    if (graphQLErrors) {
      graphQLErrors.forEach((error) => {
        if (
          error.extensions?.skipReporting ||
          sessionStorage.getItem(customUrlKey)
        ) {
          return;
        }

        captureNetworkError(
          new Error(error.message),
          `[GQL] ${operation.operationName}`,
          [operation.operationName, error.message],
          'graphql',
          {
            ...getExtrasForGraphQlOperation(operation),
            ...getExtrasForGraphQlError(error),
          }
        );
      });
    }
  });

  const cache = new InMemoryCache({
    possibleTypes: fragmentMatcher.possibleTypes,
    typePolicies,
  });

  const client = new ApolloClient({
    cache,
    connectToDevTools: !isAdHocClient,
    link: ApolloLink.from([errorLink, httpLink].filter(Boolean)),
  });

  const cleanTypenameLinkForMutationsLink = new ApolloLink(
    (operation, forward) => {
      const omitTypename = (key: any, value: any) =>
        key === '__typename' ? undefined : value;

      operation.variables = JSON.parse(
        JSON.stringify(operation.variables),
        omitTypename
      );
      return forward ? forward(operation) : null;
    }
  );

  const refreshLink = new ApolloLink((request, forward) => {
    if (!ensureTokensLiveness) {
      return forward(request);
    }

    return fromPromise(ensureTokensLiveness()).flatMap(() => forward(request));
  });

  const authLink = setContext((_, { headers }) => {
    const mergedHeaders = {
      ...getDefaultHeaders(),
      ...headers,
    };
    if (getToken) {
      const token = getToken();
      if (!mergedHeaders['Authorization'] && token) {
        Object.assign(mergedHeaders, { Authorization: `Bearer ${token}` });
      }
    }
    // return the headers to the context so httpLink can read them
    return {
      headers: mergedHeaders,
    };
  });

  const link = ApolloLink.from(
    [
      errorLink,
      refreshLink,
      authLink,
      cleanTypenameLinkForMutationsLink,
      httpLink,
    ].filter(Boolean)
  );

  client.setLink(link);

  return client;
};
